import React from 'react';
import { Typography, Container, Link, Divider, Paper } from '@mui/material';
import Footer from '../components/Footer';

const Terms = () => {
    return (
        <>
            <Container maxWidth="md" sx={{ pt: 8, pb: 8 }}>
                <Typography variant="h5" component="h1" gutterBottom>
                    Terms of Service
                </Typography>
                <Paper
                    elevation={3}
                    sx={{
                        maxHeight: 'calc(100vh - 250px)',
                        overflowY: 'auto',
                        p: 4,
                        mb: 4
                    }}
                >
                    <Typography variant="body2" paragraph>
                        Last updated: {new Date().toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}
                    </Typography>
                    <Typography variant="body2" paragraph>
                        Welcome to SiteReviewDesk.ai. By using our website and services, you agree to comply with and be bound by the following terms and conditions.
                    </Typography>

                    <Typography variant="h7" gutterBottom>
                        1. Service Description
                    </Typography>
                    <Typography variant="body2" paragraph>
                        SiteReviewDesk.ai provides an AI-powered website analysis service that generates reports on content, structure, and SEO potential. Our service is offered through tiered subscriptions: Basic (Free), Plus, and Pro.
                    </Typography>

                    <Typography variant="h7" gutterBottom>
                        2. User Accounts
                    </Typography>
                    <Typography variant="body2" paragraph>
                        You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account. You must immediately notify us of any unauthorized use of your account.
                    </Typography>

                    <Typography variant="h7" gutterBottom>
                        3. Subscription and Payments
                    </Typography>
                    <Typography variant="body2" paragraph>
                        Paid subscriptions are billed in advance on a monthly basis. You are responsible for paying all fees associated with your account. If you choose to upgrade or downgrade your subscription, the changes will take effect at the start of the next billing cycle.
                    </Typography>

                    <Typography variant="h7" gutterBottom>
                        4. Acceptable Use
                    </Typography>
                    <Typography variant="body2" paragraph>
                        You agree not to use our service to analyze websites that you do not own or have permission to analyze. You also agree not to attempt to reverse-engineer our AI models or use our service in any way that could damage, disable, or impair our systems.
                    </Typography>

                    <Typography variant="h7" gutterBottom>
                        5. Intellectual Property
                    </Typography>
                    <Typography variant="body2" paragraph>
                        The content, features, and functionality of the SiteReviewDesk.ai service are owned by us and are protected by international copyright, trademark, and other intellectual property laws.
                    </Typography>

                    <Typography variant="h7" gutterBottom>
                        6. Limitation of Liability
                    </Typography>
                    <Typography variant="body2" paragraph>
                        SiteReviewDesk.ai is not liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use of our service or any content provided in our reports.
                    </Typography>

                    <Typography variant="h7" gutterBottom>
                        7. Modifications to the Service
                    </Typography>
                    <Typography variant="body2" paragraph>
                        We reserve the right to modify or discontinue, temporarily or permanently, the service with or without notice. You agree that we will not be liable to you or any third party for any modification, suspension, or discontinuance of the service.
                    </Typography>

                    <Typography variant="h7" gutterBottom>
                        8. Governing Law
                    </Typography>
                    <Typography variant="body2" paragraph>
                        These Terms shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law provisions.
                    </Typography>

                    <Typography variant="h7" gutterBottom>
                        9. Changes to Terms
                    </Typography>
                    <Typography variant="body2" paragraph>
                        We reserve the right to update or change our Terms of Service at any time. We will notify users of any changes by posting the new Terms of Service on this page.
                    </Typography>

                    <Typography variant="h7" gutterBottom>
                        10. Contact Us
                    </Typography>
                    <Typography variant="body2" paragraph>
                        If you have any questions about these Terms of Service, please contact us at{' '}
                        <Link href="mailto:support@sitereviewdesk.ai">support@sitereviewdesk.ai</Link>.
                    </Typography>
                </Paper>
            </Container>
            <Divider />
            <Footer />
        </>
    );
};

export default Terms;