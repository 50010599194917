import React from 'react'; // Added useEffect
import { Container, Typography, Button, Box } from '@mui/material'; // Import necessary components


function SubscriptionCancelled() {

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pt: { xs: 4, sm: 4 },
        pb: { xs: 4, sm: 4 },
        maxWidth: 600,
      }}>
      <Typography variant="h4">Subscription Process Cancelled</Typography>
      <Box sx={{ mb: 2 }} />
      <Typography variant="body1">
        You've cancelled the subscription process. If this was a mistake or you have any questions, please don't hesitate to contact us.
      </Typography>
      <Button onClick={() => window.history.back()} variant="contained" sx={{ mt: 2 }}>
        Go Back
      </Button>
      {/* Add more content or CTAs as needed */}
    </Container>
  );
}

export default SubscriptionCancelled;