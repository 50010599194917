import React from 'react';
import { Typography, Box } from '@mui/material';
import Link from '@mui/material/Link';
import Footer from '../components/Footer';
import { Divider, Container } from '@mui/material';

const Contact = () => {
    return (
        <>

            <Container maxWidth="md" sx={{ pt: 8, pb: 8 }}>
                <Box sx={{ height: '50px' }} />
                <Box>
                    <Typography variant="h4" gutterBottom>
                        Contact Us
                    </Typography>
                    <Typography variant="body1" paragraph>
                        If you have any questions or need support, please reach out to us at:
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <strong>Email:</strong> <Link href="mailto:support@sitereviewdesk.ai">support@sitereviewdesk.ai</Link>
                    </Typography>
                </Box>
                <Box mb={30} />
            </Container>
            <Divider />
            <Footer />
        </>
    );
};

export default Contact;