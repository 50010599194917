import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  AppBar, Toolbar, Button, Container, Typography, MenuItem,
  Box, Avatar, IconButton, Menu
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from "../static/img/Transparent_SRD_Logo.png";
import { useAuth } from '../AuthContext';
import { getAuth, signOut } from 'firebase/auth';
import Divider from '@mui/material/Divider';

const logoStyle = {
  width: '175px',
  height: 'auto',
  cursor: 'pointer',
  marginTop: '10px',
};


const AppAppBar = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = React.useState(null);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const auth = getAuth();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMenuAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Error logging out: ', error);
      // TODO: Add user-facing error message
    }
  };

  const env = process.env.REACT_APP_ENV;

  const menuItems = [
    { label: 'Home', to: '/' },
    { label: 'Features', to: '/features' },
    { label: 'Highlights', to: '/highlights' },
    { label: 'Pricing', to: '/pricing' },
    { label: 'FAQ', to: '/faq' },
    { label: 'Run Review', to: '/review', button: true },
    ...(currentUser ? [{ label: 'Dashboard', to: '/dashboard' }] : []),
    ...(env === 'dev' ? [{ label: 'Debug', to: '/debug', color: 'warning.main' }] : []),
  ];

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
    >
      <div>
        {currentUser && (
          <MenuItem component={Link} to="/profile" onClick={handleMenuClose}>
            <Typography variant="body2">View Profile</Typography>
          </MenuItem>
        )}
        {currentUser && (
          <MenuItem onClick={() => { handleLogout(); handleMenuClose(); }}>
            <Typography variant="body2">Logout</Typography>
          </MenuItem>
        )}
      </div>
    </Menu>
  );

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMenuAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      open={Boolean(mobileMenuAnchorEl)}
      onClose={handleMobileMenuClose}
    >
      <div>
        {menuItems.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              if (item.action) item.action();
              if (item.to) navigate(item.to);
              handleMobileMenuClose();
            }}
          >
            {item.label === 'Run Review' ? (
              <Typography variant="body2" color={item.color || 'primary'}>
                {item.label}
              </Typography>
            ) : (
              <Typography variant="body2" color={item.color || 'text.primary'}>
                {item.label}
              </Typography>
            )}
          </MenuItem>
        ))}
        {currentUser ? (
          <>
            <Divider />
            <MenuItem
              onClick={() => {
                handleLogout();
                handleMobileMenuClose();
              }}
            >
              <Typography variant="body2">Logout</Typography>
            </MenuItem>
          </>
        ) : (
          <div>
            <Divider />
            <MenuItem
              onClick={() => {
                navigate('/login');
                handleMobileMenuClose();
              }}
            >
              <Typography variant="body2">Sign in</Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate('/signup');
                handleMobileMenuClose();
              }}
            >
              <Typography variant="body2">Sign up</Typography>
            </MenuItem>
          </div>
        )}
      </div>
    </Menu>
  );

  return (
    <AppBar position="fixed" sx={{ boxShadow: 0, bgcolor: 'transparent', backgroundImage: 'none', mt: 2 }}>
      <Container maxWidth="lg">
        <Toolbar variant="regular" sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexShrink: 0,
          borderRadius: '999px',
          bgcolor:
            theme.palette.mode === 'light'
              ? 'rgba(255, 255, 255, 0.4)'
              : 'rgba(0, 0, 0, 0.4)',
          backdropFilter: 'blur(24px)',
          maxHeight: 40,
          border: '1px solid',
          borderColor: 'divider',
          boxShadow:
            theme.palette.mode === 'light'
              ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
              : '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
        })}>
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
            <Link to="/">
              <img src={Logo} style={logoStyle} alt="Site Review Desk logo" />
            </Link>
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              {menuItems.map((item, index) => (
                item.button ? (
                  <Button
                    key={index}
                    color="primary"
                    variant="contained"
                    size="small"
                    component={Link}
                    to={item.to}
                    sx={{ m: 1, fontSize: '0.8rem', whiteSpace: 'nowrap', textTransform: 'capitalize' }}
                  >
                    {item.label}
                  </Button>
                ) : (
                  item.label !== 'Home' ? (
                    <Button
                      key={index}
                      onClick={item.action || (() => navigate(item.to))}
                      sx={{ color: item.color || 'text.primary', m: 1, whiteSpace: 'nowrap', fontSize: '0.8rem', textTransform: 'capitalize' }}
                    >
                      {item.label}
                    </Button>
                  ) : null
                )
              ))}
            </Box>
          </Box>

          {env !== 'prod' && (
            <Box sx={{ bgcolor: env === 'dev' ? 'error.main' : 'warning.main', color: 'white', borderRadius: 1, px: 1, py: 0.5, fontSize: '0.8rem', mr: 2 }}>
              {env.toUpperCase()}
            </Box>
          )}

          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            {currentUser ? (
              <Button onClick={handleMenuOpen} startIcon={<Avatar alt={currentUser.email} src={currentUser.photoURL} />}>
                <Typography variant="body2" sx={{ color: 'text.primary', textTransform: 'none', fontSize: '0.7rem' }}>
                  {currentUser.displayName}
                </Typography>
              </Button>
            ) : (
              <>
                <Button color="primary" variant="text" size="small" component={Link} to="/login" sx={{ fontSize: '0.7rem', textTransform: 'uppercase' }}>
                  Sign in
                </Button>
                <Button color="primary" variant="contained" size="small" component={Link} to="/signup" sx={{ ml: 2, fontSize: '0.7rem', textTransform: 'uppercase' }}>
                  Sign up
                </Button>
              </>
            )}
          </Box>

          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls="mobile-menu"
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </Container>
      {renderMenu}
      {renderMobileMenu}
    </AppBar>
  );
}

export default AppAppBar;
