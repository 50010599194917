
import Pricing from '../components/Pricing';
import Footer from '../components/Footer';
import { Box, Divider } from '@mui/material';

export default function LandingPage() {
    return (
        <Box sx={{ bgcolor: 'background.default' }}>
            <Pricing />
            <Divider />
            <Footer />
        </Box>
    );
}
