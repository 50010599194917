import Highlights from '../components/Highlights';
import Footer from '../components/Footer';
import { Box } from '@mui/material';

export default function LandingPage() {
    return (
        <Box sx={{ bgcolor: 'background.default' }}>
            <Highlights />
            <Footer />
        </Box>
    );
}
