import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import { getStorage, ref, getDownloadURL } from "firebase/storage";

const PageReviewModal = ({ open, onClose, pages, initialPageIndex = 0 }) => {
  const [currentPageIndex, setCurrentPageIndex] = useState(initialPageIndex);
  const [resolvedImageUrl, setResolvedImageUrl] = useState(null);

  useEffect(() => {
    setCurrentPageIndex(initialPageIndex);
  }, [initialPageIndex]);

  useEffect(() => {
    const resolveImageUrl = async () => {
      if (pages && pages.length > 0) {
        const currentPage = pages[currentPageIndex];
        if (currentPage.screenshot_path) {
          try {
            const storage = getStorage();
            const imageRef = ref(storage, currentPage.screenshot_path);
            const url = await getDownloadURL(imageRef);
            setResolvedImageUrl(url);
          } catch (error) {
            console.error(`Error fetching image for ${currentPage.url}:`, error);
            setResolvedImageUrl(null);
          }
        } else {
          setResolvedImageUrl(null);
        }
      }
    };

    resolveImageUrl();
  }, [currentPageIndex, pages]);

  if (!pages || pages.length === 0) {
    return null;
  }

  const handlePrevious = () => {
    setCurrentPageIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : pages.length - 1
    );
  };

  const handleNext = () => {
    setCurrentPageIndex((prevIndex) =>
      prevIndex < pages.length - 1 ? prevIndex + 1 : 0
    );
  };

  const currentPage = pages[currentPageIndex];

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='page-review-modal-title'
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        maxWidth: 1000,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        outline: 'none',
        borderRadius: 2,
        maxHeight: '90vh',
        overflow: 'auto',
        border: '1px solid #e0e0e0',
      }}>
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 1,
            top: 1,
            color: (theme) => theme.palette.grey[200],
          }}
        >
          <CloseIcon />
        </IconButton>

        <Grid container spacing={2}>
          <Grid item xs={3} sm={4}>
            <Typography variant='h5' component='h2' gutterBottom color='primary'>
              Page Details
            </Typography>
            <Typography variant='body2'>URL: {currentPage.url}</Typography>
          </Grid>
          <Grid item xs={3} sm={4}>
            <Paper elevation={3} sx={{ p: 1, mb: 1 }}>
              <Typography variant='body2'>Content Score: {currentPage.vision_llm_analysis?.content_score || 'N/A'}</Typography>
              <Typography variant='body2'>Design Score: {currentPage.vision_llm_analysis?.design_score || 'N/A'}</Typography>
              <Typography variant='body2'>Visual Score: {currentPage.vision_llm_analysis?.visual_score || 'N/A'}</Typography>
              <Typography variant='body2'>Call To Action Score: {currentPage.vision_llm_analysis?.cta_score || 'N/A'}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={3} sm={4}>
            <Paper elevation={3} sx={{ p: 1, mb: 1, display: 'flex', justifyContent: 'space-between' }}>
              <IconButton onClick={handlePrevious}>
                <ArrowBackIosNewIcon />
                <Typography variant='body2' color='primary'>Previous</Typography>
              </IconButton>
              <IconButton onClick={handleNext}>
                <Typography variant='body2' color='primary'>Next</Typography>
                <ArrowForwardIosIcon />
              </IconButton>
            </Paper>
          </Grid>
        </Grid>

        <Box sx={{
          position: 'relative',
          mb: 2,
          maxHeight: '40vh', // Set max height for the container
          overflowY: 'auto', // Enable vertical scrollbar
          border: 1,
          borderColor: 'primary.main',
          borderRadius: 2,
        }}>
          {resolvedImageUrl ? (
            <img
              src={resolvedImageUrl}
              alt={`Screenshot of ${currentPage.url}`}
              style={{ width: '100%', height: 'auto' }} // Maintain aspect ratio
            />
          ) : (
            <Typography variant="body2" align="center" sx={{ py: 2 }}>
              No screenshot available
            </Typography>
          )}
        </Box>
        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
          <Grid item xs={12}>
            <Typography variant='h5' component='h2' gutterBottom>
              Description
            </Typography>
            <Typography variant='body2'>{currentPage.vision_llm_analysis?.content_description}</Typography>
          </Grid>
        </Paper>
        {currentPage.vision_llm_analysis?.recommendations && (
          <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
            <Typography variant='h6' gutterBottom>Recommendations</Typography>
            <TableContainer>
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Effort</TableCell>
                    <TableCell>Impact</TableCell>
                    <TableCell>Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentPage.vision_llm_analysis.recommendations.map((rec, index) => (
                    <TableRow key={index}>
                      <TableCell>{rec.title}</TableCell>
                      <TableCell>{rec.action_description}</TableCell>
                      <TableCell>{rec.effort}</TableCell>
                      <TableCell>{rec.impact}</TableCell>
                      <TableCell>{rec.value}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}

        {currentPage.vision_llm_analysis?.overall_summary && (
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant='h6' gutterBottom>Overall Summary</Typography>
            <Typography variant='body2'>{currentPage.vision_llm_analysis.overall_summary}</Typography>
          </Paper>
        )}
      </Box>
    </Modal>
  );
};

export default PageReviewModal;