import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
  Container, Typography, List, ListItem, CircularProgress, Divider,
  Paper, Box, IconButton, Grid, Chip, useTheme,
  TextField, Drawer, Button, Avatar, Menu, MenuItem,
  Tabs, Tab, Skeleton
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import { Link } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { getAuth, signOut } from 'firebase/auth';
import DeleteIcon from '@mui/icons-material/Delete';
import LinkIcon from '@mui/icons-material/Link';
import FileOpenOutlinedIcon from '@mui/icons-material/FileOpenOutlined';
import MUITooltip from '@mui/material/Tooltip';
import { API_BASE_URL } from '../config'
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../components/FormatDates';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FilterListIcon from '@mui/icons-material/FilterList';
import SortIcon from '@mui/icons-material/Sort';
import SearchIcon from '@mui/icons-material/Search';
import HomeLogo from "../static/img/Transparent_SRD_Logo.png";
import axios from 'axios';
import MenuIcon from '@mui/icons-material/Menu';
import { analytics } from '../config';
import { logEvent } from "firebase/analytics";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import AddIcon from '@mui/icons-material/Add';

// Extend dayjs with customParseFormat plugin
dayjs.extend(customParseFormat);

// Extend dayjs with UTC plugin
dayjs.extend(utc);

const Dashboard = () => {
  const { currentUser } = useAuth();
  const auth = getAuth();
  const navigate = useNavigate();
  const [reports, setReports] = useState([]);
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeTab, setActiveTab] = useState('all');
  const [profileDetails, setProfileDetails] = useState({});
  const [mobileOpen, setMobileOpen] = useState(false);
  // State variables for filtering and sorting
  const [sortBy, setSortBy] = useState('date');
  const [filterLevel, setFilterLevel] = useState('all');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [resolvedImageUrls, setResolvedImageUrls] = useState({});
  const [profileLoading, setProfileLoading] = useState(true);
  const [reportsLoading, setReportsLoading] = useState(true);

  useEffect(() => {
    logEvent(analytics, 'page_view', { page_name: 'dashboard' });
  }, []);

  useEffect(() => {
    const fetchReports = async () => {
      if (currentUser) {
        setReportsLoading(true);
        try {
          const timestamp = Date.now();
          const response = await fetch(`${API_BASE_URL}/api/v1/reports?user_id=${currentUser.uid}&t=${timestamp}`);
          if (!response.ok) throw new Error('Failed to fetch reports');
          const reportsList = await response.json();
          setReports(reportsList);
        } catch (error) {
          console.error('Error fetching reports:', error);
        } finally {
          setReportsLoading(false);
        }
      }
    };

    const fetchProfileDetails = async () => {
      if (currentUser) {
        setProfileLoading(true);
        try {
          const timestamp = Date.now();
          const response = await axios.get(`${API_BASE_URL}/api/v1/users/${currentUser.uid}?t=${timestamp}`);
          const userData = response.data;
          setProfileDetails(userData);
        } catch (error) {
          console.error('Error fetching profile details:', error);
        } finally {
          setProfileLoading(false);
        }
      }
    };
    fetchReports();
    fetchProfileDetails();
  }, [currentUser]);

  useEffect(() => {
    const resolveImageUrls = async (reports) => {
      const storage = getStorage();
      const resolvedUrls = {};
      for (const report of reports) {
        if (report.screenshot_paths && report.screenshot_paths[report.url]) {
          try {
            const imageRef = ref(storage, report.screenshot_paths[report.url]);
            const url = await getDownloadURL(imageRef);
            resolvedUrls[report.url] = url;
          } catch (error) {
            console.error(`Error fetching image for ${report.url}:`, error);
            resolvedUrls[report.url] = null;
          }
        }
      }
      setResolvedImageUrls(resolvedUrls);
    };

    if (reports.length > 0) {
      resolveImageUrls(reports);
    }
  }, [reports]);

  const filteredReports = useMemo(() => {
    return reports.filter(report => {
      const isManualUpload = report.type === 'manual_review';
      
      const urlToCheck = isManualUpload ? 'Manual Upload Review' : report.url;
      const matchesSearch = searchTerm === '' || 
        urlToCheck.toLowerCase().includes(searchTerm.toLowerCase());

      const matchesLevel = filterLevel === 'all' || report.report_level === filterLevel;
      const matchesDate = (!startDate || dayjs(report.created_at).isAfter(startDate)) &&
        (!endDate || dayjs(report.created_at).isBefore(endDate));

      return matchesSearch && matchesLevel && matchesDate;
    });
  }, [reports, searchTerm, filterLevel, startDate, endDate]);


  const getScoreColor = (score) => {
    if (score >= 90) return 'success.main';
    if (score >= 80) return 'lightgreen';
    if (score >= 70) return 'warning.main';
    if (score >= 60) return 'orange';
    return 'error.main';
  };

  const handleDelete = async (report_id, event) => {
    event.preventDefault();
    event.stopPropagation();
    if (window.confirm('Are you sure you want to delete this report? This will also delete all related pages.')) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/v1/reports/${report_id}`, {
          method: 'DELETE',
        });
        if (!response.ok) throw new Error('Failed to delete report');

        // Fetch the updated list of reports from the server
        const updatedReportsResponse = await fetch(`${API_BASE_URL}/api/v1/reports?user_id=${currentUser.uid}&t=${Date.now()}`);
        if (!updatedReportsResponse.ok) throw new Error('Failed to fetch updated reports');
        const updatedReports = await updatedReportsResponse.json();

        // Update the local state with the fresh data from the server
        setReports(updatedReports);
      } catch (error) {
        console.error('Error deleting report:', error);
        alert(`Error deleting report: ${error.message}`);
      }
    }
  };

  const getSubscriptionCounts = () => {
    const counts = reports.reduce((acc, report) => {
      acc[report.url] = (acc[report.url] || 0) + 1;
      return acc;
    }, {});
    return counts;
  };

  const subscriptionCounts = getSubscriptionCounts();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Failed to log out', error);
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDrawerClose = () => {
    setMobileOpen(false);
  };

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    handleDrawerClose();
  };

  const handleChipClick = useCallback((url) => {
    setSearchTerm(url);
  }, []);

  const drawerContent = (
    <Box sx={{ width: 240, mt: 2, p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Link to="/" style={{ textDecoration: 'none' }} onClick={handleDrawerClose}>
        <img src={HomeLogo} alt="Logo" style={{ maxWidth: '100%', marginBottom: '16px' }} />
      </Link>
      <Divider />
      <Button variant="contained" href="/review" fullWidth sx={{ justifyContent: 'flex-start', mb: 2 }} onClick={handleDrawerClose}>Review A Site</Button>
      <Divider sx={{ mb: 1 }}/>
      <Box component="div">
        <Typography variant="h6" sx={{ mb: 2 }}>Dashboard Sections</Typography>
      </Box>
      <Button color="inherit" onClick={() => scrollToSection('sites-reviewed')} fullWidth sx={{ justifyContent: 'flex-start', mb: 1 }}>Unique Sites Reviewed</Button>
      <Button color="inherit" onClick={() => scrollToSection('list-of-reports')} fullWidth sx={{ justifyContent: 'flex-start', mb: 1 }}>List of Reports</Button>

      {/* Spacer to push the following items to the bottom */}
      <Box sx={{ flexGrow: 1 }} />

      <Divider />
      <Button color="inherit" component={Link} to="/" sx={{ justifyContent: 'flex-start', mb: 1 }} onClick={handleDrawerClose}>
        Home
      </Button>
      <Button color="inherit" component={Link} to="/pricing" sx={{ justifyContent: 'flex-start', mb: 1 }} onClick={handleDrawerClose}>
        Pricing
      </Button>
      <Button color="inherit" component={Link} to="/faq" sx={{ justifyContent: 'flex-start', mb: 1 }} onClick={handleDrawerClose}>
        FAQ
      </Button>
      {currentUser ? (
        <>
          <Button
            color="inherit"
            onClick={(event) => {
              handleMenu(event);
              handleDrawerClose();
            }}
            startIcon={
              currentUser.photoURL ? (
                <Avatar src={currentUser.photoURL} sx={{ width: 32, height: 32 }} />
              ) : (
                <AccountCircleIcon />
              )
            }
            sx={{ justifyContent: 'flex-start', mb: 1 }}
          >
            {currentUser.displayName || currentUser.email}
          </Button>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => { handleClose(); handleDrawerClose(); }} component={Link} to="/profile">Profile</MenuItem>
            <MenuItem onClick={() => { handleLogout(); handleDrawerClose(); }}>Logout</MenuItem>
          </Menu>
        </>
      ) : (
        <Button color="inherit" component={Link} to="/login" sx={{ justifyContent: 'flex-start', mb: 1 }} onClick={handleDrawerClose}>
          Login
        </Button>
      )}
    </Box>
  );

  const SummaryCardSkeleton = () => (
    <Skeleton variant="rectangular" width="100%" height={100} />
  );

  const ReportListSkeleton = () => (
    <List>
      {[...Array(5)].map((_, index) => (
        <ListItem key={index}>
          <Skeleton variant="rectangular" width="100%" height={100} />
        </ListItem>
      ))}
    </List>
  );

  if (profileLoading || reportsLoading) {
    return (
      <Container maxWidth="md" sx={{ mt: 4, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Box sx={{ display: 'flex' }}>
      {/* // ========================================================================== //
      //         Mobile Drawer
      // ========================================================================== // */}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
        }}
      >
        {/* // ========================================================================== //
        //         Drawer Content
        // ========================================================================== // */}
        {drawerContent}
      </Drawer>

      {/* // ========================================================================== //
      //         Desktop Drawer
      // ========================================================================== // */}
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
        }}
        open
      >
        {drawerContent}
      </Drawer>

      {/* Main content area */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { xs: '100%', sm: `calc(100% - 240px)` },
          ml: { xs: 0, sm: `240px` },
        }}
      >
        {/* Fixed position burger menu for mobile */}
        <Box
          sx={{
            position: 'fixed',
            top: 16,
            left: 16,
            zIndex: 1100,
            display: { xs: 'block', sm: 'none' },
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              backgroundColor: 'background.paper',
              boxShadow: 1,
              '&:hover': {
                backgroundColor: 'action.hover',
              },
            }}
          >
            <MenuIcon />
          </IconButton>
        </Box>

        {/* Add padding to the top of the content to prevent overlap with the fixed menu button */}
        <Box sx={{ pt: { xs: 7, sm: 0 } }}>
          {/* // ========================================================================== //
          //         Page Content
          // ========================================================================== // */}
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4, px: { xs: 2, sm: 3 } }}>
            <Typography variant="h4" component="h1" gutterBottom align="center" color="primary" sx={{ mb: 4 }}>
              Reports Dashboard
            </Typography>

            {/* // ========================================================================== //
            //         Summary cards
            // ========================================================================== // */}
            <Grid container spacing={3} sx={{ mb: 4 }}>
              {profileLoading ? (
                <>
                  <Grid item xs={12} sm={6} md={3}><SummaryCardSkeleton /></Grid>
                  <Grid item xs={12} sm={6} md={3}><SummaryCardSkeleton /></Grid>
                  <Grid item xs={12} sm={6} md={3}><SummaryCardSkeleton /></Grid>
                  <Grid item xs={12} sm={6} md={3}><SummaryCardSkeleton /></Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} sm={6} md={3}>
                    <MUITooltip title="Current Subscription Tier" arrow>
                      <Paper elevation={3} sx={{ p: 2, textAlign: 'center' }}>
                        <Typography variant="h7">Subscription Tier</Typography>
                        <Typography variant="h6" sx={{ textTransform: 'capitalize' }}>{profileDetails.subscription_tier}</Typography>
                      </Paper>
                    </MUITooltip>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <MUITooltip title="Total Reports (All Time)" arrow>
                      <Paper elevation={3} sx={{ p: 2, textAlign: 'center' }}>
                        <Typography variant="h7">Total Reports (All Time)</Typography>
                        <Typography variant="h6">{profileDetails.report_count}</Typography>
                      </Paper>
                    </MUITooltip>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <MUITooltip title="Total Reports This Period" arrow>
                      <Paper elevation={3} sx={{ p: 2, textAlign: 'center' }}>
                        <Typography variant="h7">Total Reports (This Period)</Typography>
                        <Typography variant="h6">{profileDetails.current_period_report_count}</Typography>
                      </Paper>
                    </MUITooltip>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <MUITooltip title="Reports Remaining This Period" arrow>
                      <Paper elevation={3} sx={{ p: 2, textAlign: 'center' }}>
                        <Typography variant="h7">Reports Remaining (This Period)</Typography>
                        <Typography variant="h6">{profileDetails.remaining_reports}</Typography>
                      </Paper>
                    </MUITooltip>
                  </Grid>
                </>
              )}
            </Grid>

            <Box id="sites-reviewed" sx={{ mb: 3 }}>
              <Typography variant="h6" gutterBottom>Unique Sites Reviewed</Typography>
              {reportsLoading ? (
                <Skeleton variant="rectangular" width="100%" height={50} />
              ) : (
                <Grid container spacing={2}>
                  {Object.entries(subscriptionCounts).map(([url, count]) => (
                    <Grid item key={url}>
                      <Chip
                        label={`${url}: ${count}`}
                        color="primary"
                        variant="outlined"
                        onClick={() => handleChipClick(url)}
                        sx={{ cursor: 'pointer' }}
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
            </Box>

            <Box sx={{ mb: 3 }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Search reports..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    InputProps={{
                      startAdornment: <SearchIcon color="primary" sx={{ mr: 1 }} />,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    select
                    fullWidth
                    variant="outlined"
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                    InputProps={{
                      startAdornment: <SortIcon color="action" sx={{ mr: 1 }} />,
                    }}
                  >
                    <MenuItem value="date">Sort by Date</MenuItem>
                    <MenuItem value="url">Sort by URL</MenuItem>
                    <MenuItem value="score">Sort by Score</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    select
                    fullWidth
                    variant="outlined"
                    value={filterLevel}
                    onChange={(e) => setFilterLevel(e.target.value)}
                    InputProps={{
                      startAdornment: <FilterListIcon color="action" sx={{ mr: 1 }} />,
                    }}
                  >
                    <MenuItem value="all">All Levels</MenuItem>
                    <MenuItem value="Basic">Basic</MenuItem>
                    <MenuItem value="Plus">Plus</MenuItem>
                    <MenuItem value="Pro">Pro</MenuItem>
                  </TextField>
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ mb: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                  <DatePicker
                    label="Start Date"
                    value={startDate}
                    onChange={(newValue) => setStartDate(newValue)}
                    slots={{
                      textField: (params) => <TextField {...params} fullWidth />
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <DatePicker
                    label="End Date"
                    value={endDate}
                    onChange={(newValue) => setEndDate(newValue)}
                    slots={{
                      textField: (params) => <TextField {...params} fullWidth />
                    }}
                  />
                </Grid>
              </Grid>
            </Box>


            {/* // ========================================================================== //
            //         New tabs for report status
            // ========================================================================== // */}
            <Tabs
              value={activeTab}
              onChange={(e, newValue) => setActiveTab(newValue)}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              sx={{ mb: 3 }}
            >
              <Tab label="All Reports" value="all" />
              <Tab label="Completed" value="completed" />
              <Tab label="In Progress" value="in progress" />
              <Tab label="Failed" value="error" />
            </Tabs>

            {/* // ========================================================================== //
            //         List of reports
            // ========================================================================== // */}
            <Grid container spacing={2} sx={{ mb: 2, mt: 1 }} id="list-of-reports">
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>List of Reports ({filteredReports.length})</Typography>
                <Typography variant="body2" gutterBottom>List of reports:</Typography>
                <Paper elevation={3} sx={{ borderRadius: 2, overflow: 'hidden' }}>
                  {reportsLoading ? (
                    <ReportListSkeleton />
                  ) : reports.length === 0 ? ( // Check if there are no reports at all
                    <Box sx={{ p: 2, textAlign: 'center' }}>
                      <Typography component="div" variant="body2" color="textSecondary">
                        You haven't created any reports yet. Start by running a review!
                      </Typography>
                      <Button 
                        variant="contained" 
                        size="small"
                        href="/review" 
                        sx={{ mt: 1, color: 'black' }}
                        startIcon={<AddIcon />}
                      >
                        Run Your First Review
                      </Button>
                    </Box>
                  ) : reports.length !== 0 && filteredReports.length === 0 ? ( // Check if there are no reports for the specific category
                  <Box sx={{ p: 2, textAlign: 'center' }}>
                    <Typography component="div" variant="body2" color="textSecondary">
                      No reports with this filter criteria found.
                    </Typography>
                  </Box>
                  ) : (
                    <List>
                      {filteredReports.map((report, index) => (
                        <ListItem
                          component={report.status === "Completed" ? Link : "div"}
                          to={report.status === "Completed" ? 
                            `/report?report_id=${report.id}&type=${report.type}&llm=${report.llm}&report_level=${report.report_level}` 
                            : undefined}
                          key={report.id}
                          sx={{
                            borderBottom: index < filteredReports.length - 1 ? `1px solid ${theme.palette.divider}` : 'none',
                            transition: 'all 0.3s',
                            '&:hover': report.status === "Completed" ? {
                              backgroundColor: theme.palette.action.hover,
                              transform: 'translateY(-2px)',
                            } : {},
                          }}
                        >
                          <Grid container spacing={3} alignItems="center">
                            <Grid item xs={12} sm={2} sx={{ mt: 2, mb: 0 }}>
                              {report.screenshot_paths[report.url] ? (
                                <Box component="div">
                                  <img
                                    src={resolvedImageUrls[report.url] || ''}
                                    alt={`Thumbnail for ${report.url}`}
                                    loading="lazy"
                                    onError={(e) => { e.target.onerror = null; e.target.src = 'path_to_your_placeholder_image.jpg' }}
                                    style={{ width: '100px', height: '100px', objectFit: 'cover', objectPosition: 'top' }}
                                  />
                                </Box>
                              ) : (
                                <Box component="div" sx={{ width: '100px', height: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#f0f0f0', border: '1px solid #ccc' }}>
                                  <Typography variant="body2" color="textSecondary">No Image Available</Typography>
                                </Box>
                              )}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Box component="div">
                                <Typography variant="body1">
                                  {report.type === 'manual_review' ? 'Manual Upload Review' : report.url}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                  {`Created at: ${formatDate(report.created_at)}`}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                  Status: {report.status}
                                  {report.type === 'manual_review' && ' • Manual Upload'}
                                </Typography>
                              </Box>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
                                <Box position="relative" display="flex" alignItems="center" justifyContent="center" mb={0} sx={{ mr: 2 }}> {/* Added margin to separate from icons */}
                                  <Paper elevation={2} sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'center', height: '80px' }}>
                                    <Box position="relative" display="flex" alignItems="center" justifyContent="center">
                                      <Typography variant="body2" component="div" sx={{ position: 'absolute', top: -38, color: 'text.primary' }}>Score</Typography>
                                      <CircularProgress
                                        variant="determinate"
                                        value={report.overall_score || 0} // Default to 0 if overall_score is not valid
                                        size={50}
                                        thickness={7}
                                        sx={{ color: getScoreColor(report.overall_score) }}
                                      />
                                    </Box>
                                    <Box
                                      position="absolute"
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="center"
                                    >
                                      <Typography variant="body2" component="div" sx={{ color: getScoreColor(report.overall_score) }}>
                                        {isNaN(report.overall_score) ? 'N/A' : `${Math.round(report.overall_score)}%`} {/* Check for NaN */}
                                      </Typography>
                                    </Box>
                                  </Paper>
                                </Box>
                                <MUITooltip title="Open" arrow>
                                  <IconButton color="primary" size="small" variant="outlined" sx={{ mr: 1 }} disabled={report.status !== "Completed"}> {/* Disable if not completed */}
                                    <FileOpenOutlinedIcon fontSize="small" />
                                  </IconButton>
                                </MUITooltip>
                                <MUITooltip title="Goto URL" arrow>
                                  <IconButton aria-label="view url" color="primary" sx={{ mr: 1 }} onClick={(e) => {
                                    e.preventDefault();
                                    if (report.status === "Completed") {
                                      window.open(report.url, '_blank');
                                    }
                                  }} disabled={report.status !== "Completed"}> {/* Disable if not completed */}
                                    <LinkIcon />
                                  </IconButton>
                                </MUITooltip>
                                <MUITooltip title="Delete Report (dev only)" arrow>
                                  <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    onClick={(e) => handleDelete(report.id, e)}
                                    sx={{
                                      color: theme.palette.error.main,
                                      '&:hover': { backgroundColor: theme.palette.error.light },
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </MUITooltip>
                              </Box>
                            </Grid>

                          </Grid>
                        </ListItem>
                      ))}
                    </List>
                  )}
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;