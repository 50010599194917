import * as React from 'react';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Add this import
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Divider } from '@mui/material';
import { googleProvider } from '../config';
import GoogleLogo from "../static/img/web_light_rd_SI@1x.png";
import { signInWithPopup } from 'firebase/auth';
import { API_BASE_URL } from '../config';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://sitereviewdesk.ai">
        SiteReviewDesk
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function SignUp() {
  const [error, setError] = React.useState(null);
  const navigate = useNavigate(); // Add this line
  const auth = getAuth();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    const password = data.get('password');
    const firstName = data.get('firstName');
    const lastName = data.get('lastName');
    const displayName = `${firstName} ${lastName}`.trim();

    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed up 
        const user = userCredential.user;
        console.log("User signed up:", user);

        // Save user data to backend
        axios.post(`${process.env.REACT_APP_API_URL}/api/v1/users`, {
          uid: user.uid,
          email: user.email,
          first_name: firstName,
          last_name: lastName,
          display_name: displayName,
          photo_url: user.photoURL,
        })
          .then(() => {
            console.log("User data saved to backend");
            navigate('/'); // Add this line to redirect to Dashboard
          })
          .catch((error) => {
            console.error("Error saving user data to backend:", error);
            setError("Error saving user data. Please try again.");
          });
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const handleGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;

      // Split displayName into first and last name
      let firstName = '';
      let lastName = '';
      if (user.displayName) {
        const nameParts = user.displayName.split(' ');
        firstName = nameParts[0];
        lastName = nameParts.slice(1).join(' '); // This will handle middle names as part of the last name
      }

      // Send user data to backend to create Firestore entry if it doesn't exist
      await axios.post(`${API_BASE_URL}/api/v1/users`, {
        uid: user.uid,
        email: user.email,
        first_name: firstName,
        last_name: lastName,
        display_name: user.displayName,
        photo_url: user.photoURL,
      });

      navigate('/');
    } catch (error) {
      console.error('Error logging in with Google: ', error);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="given-name"
                name="firstName"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="family-name"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
              />
            </Grid>
          </Grid>
          {error && (
            <Typography color="error" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign Up
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="/login" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Divider sx={{ marginY: 4 }}>OR</Divider>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
        
        <div style={{ cursor: 'pointer', transition: 'opacity 0.3s' }} onClick={handleGoogleLogin}>
          <img src={GoogleLogo} alt="Google Logo" className="google-logo" />
        </div>
      </Box>
      <Copyright sx={{ mt: 10 }} />
    </Container>
  );
}
