import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { getAuth, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { googleProvider } from '../config';
import GoogleLogo from "../static/img/web_light_rd_SI@1x.png"; // Adjust the path to match your project structure
import { Divider } from '@mui/material';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL } from '../config';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        SiteReviewDesk.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function SignIn() {
  const location = useLocation();
  const message = location.state?.message;
  const from = location.state?.from;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();
  const auth = getAuth();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Send user data to backend to create Firestore entry if it doesn't exist
      await axios.post(`${API_BASE_URL}/api/v1/users`, {
        uid: user.uid,
        email: user.email,
        display_name: user.display_name,
        photo_url: user.photo_url,
      });

      navigate('/');
    } catch (error) {
      console.error('Error logging in: ', error);
      setErrorMessage("Invalid username or password");
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;

      // Split displayName into first and last name
      let firstName = '';
      let lastName = '';
      if (user.displayName) {
        const nameParts = user.displayName.split(' ');
        firstName = nameParts[0];
        lastName = nameParts.slice(1).join(' '); // This will handle middle names as part of the last name
      }

      // Send user data to backend to create Firestore entry if it doesn't exist
      await axios.post(`${API_BASE_URL}/api/v1/users`, {
        uid: user.uid,
        email: user.email,
        first_name: firstName,
        last_name: lastName,
        display_name: user.displayName,
        photo_url: user.photoURL,
      });

      navigate('/');
    } catch (error) {
      console.error('Error logging in with Google: ', error);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        {message && <Typography color="error"> {message}{from}</Typography>}
        {errorMessage && (
          <Typography color="error">{errorMessage}</Typography>
        )}
        <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/forgot-password" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="/signup" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Divider sx={{ marginY: 4 }}>OR</Divider>
          <div style={{ cursor: 'pointer', transition: 'opacity 0.3s' }} onClick={handleGoogleLogin}>
            <img src={GoogleLogo} alt="Google Logo" className="google-logo" />
          </div>
        </Box>
      </Box>

      <Copyright sx={{ mt: 10, mb: 4 }} />
    </Container>
  );
}