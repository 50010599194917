import * as React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import AppAppBar from '../components/AppAppBar';
import Highlights from '../components/Highlights';
import Features from '../components/Features';
import FAQ from '../components/FAQ';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Demos from '../components/Demos';

export default function LandingPage() {
  return (
    <>
      <AppAppBar />
      <Hero/>
      <Divider />
      <Box sx={{ bgcolor: 'background.default' }}>
        <Divider />
        <Demos />
        <Divider />
        <Features />
        <Divider />
        <Highlights bgcolor="background.default" />
        <Divider />
        <FAQ />
        <Divider />
        <Footer />
      </Box>
    </>
  );
}
