import React from 'react';
import { Typography, Box, Container, Divider, Paper, Grid, Avatar } from '@mui/material';
import Footer from '../components/Footer';
// Import icons
import WebIcon from '@mui/icons-material/Web';
import SpeedIcon from '@mui/icons-material/Speed';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

const AboutUs = () => {
    return (
        <>
            <Container maxWidth="md" sx={{ pt: 8, pb: 8 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    About SiteReviewDesk.ai
                </Typography>
                <Typography variant="body1" paragraph>
                    SiteReviewDesk.ai is an innovative AI-powered website analysis tool designed to help businesses and professionals optimize their online presence. Our mission is to provide clear, actionable insights that drive website improvement and boost marketing effectiveness.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    Our Story
                </Typography>
                <Typography variant="body1" paragraph>
                    SiteReviewDesk.ai was founded by Alvin S. Wong, a former Cisco and OpenDNS engineer with extensive experience in Software, Network, and Security Engineering. The idea was born when Alvin was helping fellow startup founders with their websites and noticed the time it took to understand what was effective and what needed improvement.
                    <br /> <br />
                    Recognizing the potential of AI to streamline this process, he set out to create an AI-Powered website analysis tool that could automate the whole process and be more thorough and capable than any one person could perform in under 5 minutes.  The goal was to build a service that would benefit entrepreneurs, content consultants, and marketers alike.  <br /> <br />

                    By leveraging the latest AI Large Language Models (LLMs), SiteReviewDesk.ai automates the complex task of website analysis, providing actionable insights and simplifying the optimization process for businesses of all sizes.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    What We Offer
                </Typography>
                <Typography variant="body1" paragraph>
                    SiteReviewDesk.ai leverages cutting-edge AI technology to provide instant, comprehensive website analysis. Our service generates detailed reports on content, structure, and SEO potential in minutes, not weeks.
                    <br /> <br />
                    This revolutionary approach eliminates the need for time-consuming traditional consulting processes, offering you immediate, data-driven insights.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    Our Commitment
                </Typography>
                <Typography variant="body1" paragraph>
                    At SiteReviewDesk.ai, we're committed to continuous innovation and customer satisfaction. We regularly update our AI models and expand our feature set to ensure our users always have access to cutting-edge website analysis tools. Our team is dedicated to providing exceptional support and empowering our users to achieve their online goals.
                </Typography>

                <Typography variant="body1" paragraph>
                    Join us in our mission to make the web a better place, one website at a time. Try SiteReviewDesk.ai today and unlock the full potential of your online presence!
                </Typography>

                {/* Add a section for key features */}
                <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
                    Key Features
                </Typography>
                <Grid container spacing={3} sx={{ mt: 2 }}>
                    {[
                        { icon: <WebIcon />, title: 'Comprehensive Analysis', description: 'In-depth review of your website\'s structure, content, and SEO.' },
                        { icon: <SpeedIcon />, title: 'Instant Results', description: 'Get actionable insights within minutes, not weeks.' },
                        { icon: <TrendingUpIcon />, title: 'Continuous Improvement', description: 'Regular updates to our AI models for cutting-edge analysis.' },
                    ].map((feature, index) => (
                        <Grid item xs={12} sm={4} key={index}>
                            <Paper elevation={3} sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                                <Avatar sx={{ bgcolor: 'primary.main', mb: 2 }}>{feature.icon}</Avatar>
                                <Typography variant="h6" gutterBottom>{feature.title}</Typography>
                                <Typography variant="body2">{feature.description}</Typography>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>

                {/* Add a call-to-action */}
                <Box sx={{ mt: 6, textAlign: 'center' }}>
                    <Typography variant="h5" gutterBottom>Ready to optimize your website?</Typography>
                    <Typography variant="body1" paragraph>
                        Start your journey with SiteReviewDesk.ai today and see the difference AI-powered analysis can make.
                    </Typography>
                    {/* Add a button component here linking to your signup or demo page */}
                </Box>

            </Container>
            <Divider />
            <Footer />
        </>
    );
};

export default AboutUs;