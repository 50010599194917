import React, { useState } from 'react';
import ReviewForm from '../components/ReviewForm';
import ManualUploadReviewForm from '../components/ManualUploadReviewForm';
import Footer from '../components/Footer';
import { Box, Container,Divider, ToggleButtonGroup, ToggleButton, Paper, Typography, Tooltip, IconButton } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import ImageIcon from '@mui/icons-material/Image';
import HomeLogo from "../static/img/Transparent_Logo_symbol.svg";
import { useMediaQuery } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


export default function ReviewPage() {
  const [reviewType, setReviewType] = useState('url'); // 'url' or 'image'

  const handleReviewTypeChange = (event, newType) => {
    if (newType !== null) {
      setReviewType(newType);
    }
  };

  // Detect if the device is mobile
  const isMobile = useMediaQuery('(max-width:600px)'); // Adjust the breakpoint as needed

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pt: { xs: 6, sm: 6, md: 10, lg: 10 },
        pb: { xs: 6, sm: 6, md: 8, lg: 8 },
      }}>
          <img src={HomeLogo} alt="Home Logo" style={{ maxWidth: isMobile ? '100px' : '125px', marginBottom: isMobile ? '10px' : '20px' }} />
      <Typography variant="h2" sx={{ marginBottom: isMobile ? '5px' : '10px', fontSize: isMobile ? '1.5rem' : null }}>Site Review Desk</Typography>
      <Typography variant="h5" sx={{
        color: 'primary.main',
        fontStyle: 'italic',
        marginBottom: isMobile ? '10px' : '20px',
        fontSize: isMobile ? '1rem' : null,
      }}>AI-Powered Website Reviews</Typography>
    <Box sx={{ bgcolor: 'background.default' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
        <Paper 
          elevation={0}
          sx={{ 
            p: 1, 
            backgroundColor: 'grey.80',
            border: '1px solid',
            borderColor: 'grey.200',
            borderRadius: 2
          }}
        >
          <ToggleButtonGroup
            value={reviewType}
            exclusive
            onChange={handleReviewTypeChange}
            aria-label="review type"
            size="small"
          >
            <ToggleButton 
              value="url" 
              aria-label="full website review"
              sx={{ px: 2 }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <LanguageIcon sx={{ fontSize: '1.2rem' }} />
                <Typography variant="body2" sx={{ fontSize: '0.875rem' }}>Website URL</Typography>
                <Tooltip title="Automatically crawl and analyze your website by providing its URL" arrow>
                  <IconButton size="small" sx={{ ml: 0.5, p: 0 }}>
                    <InfoOutlinedIcon sx={{ fontSize: '1rem' }} />
                  </IconButton>
                </Tooltip>
              </Box>
            </ToggleButton>
            <ToggleButton 
              value="image" 
              aria-label="single page review"
              sx={{ px: 2 }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <ImageIcon sx={{ fontSize: '1.2rem' }} />
                <Typography variant="body2" sx={{ fontSize: '0.875rem' }}>Screenshot</Typography>
                <Tooltip title="Upload screenshots of your website for manual review" arrow>
                  <IconButton size="small" sx={{ ml: 0.5, p: 0 }}>
                    <InfoOutlinedIcon sx={{ fontSize: '1rem' }} />
                  </IconButton>
                </Tooltip>
              </Box>
            </ToggleButton>
          </ToggleButtonGroup>
        </Paper>
      </Box>

      {reviewType === 'url' ? <ReviewForm /> : <ManualUploadReviewForm />}
      <Divider />
      <Footer />
    </Box>
    </Container>
  );
}
