import React from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { Button } from '@mui/material';
import { useAuth } from '../AuthContext';


const CheckoutForm = ({ product, text }) => {
    const stripe = useStripe();
    const { currentUser } = useAuth();

    const handleClick = async (event) => {
        const result = await stripe.redirectToCheckout({
            lineItems: [{ price: product.price, quantity: product.quantity }],
            mode: 'subscription',
            successUrl: `${process.env.REACT_APP_API_URL}/success?session_id={CHECKOUT_SESSION_ID}&user_id=${currentUser.uid}`,
            cancelUrl: `${process.env.REACT_APP_API_URL}/cancelled`,
        });

        if (result.error) {
            console.error(result.error.message);
        }
    };

    return (
        <Button fullWidth variant="contained" color="primary" onClick={handleClick}>
            {text}
        </Button>
    );
};

export default CheckoutForm;
