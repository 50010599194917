import React, { useEffect, useState } from 'react';
import { Container, Typography, Paper, Button, TextField, Grid, CircularProgress } from '@mui/material'; // Added CircularProgress
import { useAuth } from '../AuthContext';
import { Alert, Box } from '@mui/material';
import axios from 'axios';
import { API_BASE_URL } from '../config'; // Import API_BASE_URL
import Chip from '@mui/material/Chip';
import { useNavigate } from 'react-router-dom';
import { formatDate, formatDateShort } from '../components/FormatDates';
import { Avatar } from '@mui/material';

const Profile = () => {
  const { currentUser } = useAuth();
  const [password, setPassword] = useState('');
  const [profileDetails, setProfileDetails] = useState({});
  const [isOAuthUser, setIsOAuthUser] = useState(false);
  const [userPagesCount, setUserPagesCount] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingContact, setIsEditingContact] = useState(false);
  const [title, setTitle] = useState('');
  const [company, setCompany] = useState('');
  const navigate = useNavigate();
  const [mailingInfo, setMailingInfo] = useState({
    address_line1: '',
    address_line2: '',
    city: '',
    state_province: '',
    country: '',
    zip_postal_code: ''
  });
  const [loading, setLoading] = useState(true); // Added loading state

  useEffect(() => {
    const fetchProfileDetails = async () => {
      setLoading(true); // Set loading to true when fetching starts
      if (currentUser) {
        try {
          const timestamp = Date.now();
          const response = await axios.get(`${API_BASE_URL}/api/v1/users/${currentUser.uid}?t=${timestamp}`);
          const userData = response.data;
          setProfileDetails(userData);
          setMailingInfo({
            address_line1: userData.address_line1 || '',
            address_line2: userData.address_line2 || '',
            city: userData.city || '',
            state_province: userData.state_province || '',
            country: userData.country || '',
            zip_postal_code: userData.zip_postal_code || ''
          });
          setTitle(userData.role || '');
          setCompany(userData.company || '');
        } catch (error) {
          console.error('Error fetching profile details:', error);
        } finally {
          setLoading(false); // Set loading to false when fetching ends
        }
      } else {
        setLoading(false); // Set loading to false if no currentUser
      }
    };

    const checkOAuthUser = () => {
      if (currentUser) {
        const isOAuth = currentUser.providerData.some(
          provider => provider.providerId !== 'password'
        );
        setIsOAuthUser(isOAuth);
      }
    };

    const get_user_pages_count_within_period = async () => {
      if (currentUser) {
        try {
          const timestamp = Date.now();
          const response = await fetch(`${API_BASE_URL}/api/v1/users/${currentUser.uid}/pages/count?t=${timestamp}`);
          if (!response.ok) throw new Error('Failed to fetch pages');
          const pages_count = await response.json();
          setUserPagesCount(pages_count);
        } catch (error) {
          console.error('Error fetching pages:', error);
        }
      }
    };

    checkOAuthUser();
    fetchProfileDetails();
    get_user_pages_count_within_period();
  }, [currentUser]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMailingInfo(prev => ({ ...prev, [name]: value }));
  };

  const saveUserDetails = async () => {
    if (!currentUser) return;
    try {
      const userDetails = {
        ...mailingInfo,
        role: title,
        company: company
      };
      await axios.put(`${API_BASE_URL}/api/v1/users/${currentUser.uid}`, userDetails); // Use API_BASE_URL
      setIsEditing(false);
      setIsEditingContact(false);
    } catch (error) {
      console.error('Error saving user details:', error);
    }
  };

  const handleChangePassword = async () => {
    if (currentUser && password && !isOAuthUser) {
      try {
        await axios.post(`${API_BASE_URL}/api/v1/users/${currentUser.uid}/changePassword`, { password }); // Use API_BASE_URL
        alert('Password updated successfully');
        setPassword('');
      } catch (error) {
        console.error('Error updating password:', error);
        alert('Error updating password');
      }
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 6 }}>
      <Box bgcolor="grey.900" p={2} borderRadius={1}>
        {loading ? ( // Conditional rendering for loading state
          <CircularProgress />
        ) : (
          <>
            <Typography variant="h4" component="h1" gutterBottom align="center">
              My Profile
            </Typography>
            <Grid container spacing={3} mt={2}>
              <Grid item md={6}>
                <Paper elevation={3} sx={{ p: 3, mb: 4, minHeight: '200px' }}>
                  <Typography variant="h6" gutterBottom>
                    Basic Info
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item md={4}>
                      <Avatar
                        alt={currentUser.displayName || currentUser.email}
                        src={currentUser.photoURL}
                        sx={{ width: 100, height: 100 }}
                      />
                    </Grid>
                    <Grid item md={8}>
                      <Typography variant="body2">First Name: {profileDetails?.first_name}</Typography>
                      <Typography variant="body2">Last Name: {profileDetails?.last_name}</Typography>
                      <Typography variant="body2">Email: {currentUser?.email}</Typography>
                      {isEditingContact ? (
                        <>
                          <TextField
                            fullWidth
                            margin="normal"
                            label="Title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                          <TextField
                            fullWidth
                            margin="normal"
                            label="Company"
                            value={company}
                            onChange={(e) => setCompany(e.target.value)}
                          />
                          <Button variant="contained" onClick={saveUserDetails} sx={{ mt: 2 }}>Save</Button>
                          <Button variant="outlined" onClick={() => setIsEditingContact(false)} sx={{ mt: 2, ml: 2 }}>Cancel</Button>
                        </>
                      ) : (
                        <>
                          <Typography variant="body2">Title: {title}</Typography>
                          <Typography variant="body2">Company: {company}</Typography>
                          <Button variant="contained" onClick={() => setIsEditingContact(true)} sx={{ mt: 2 }}>Edit</Button>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item md={6}>
                <Paper elevation={3} sx={{ p: 3, mb: 4, minHeight: '200px' }}>
                  <Typography variant="h6" gutterBottom>
                    Account Info
                  </Typography>
                  <Typography variant="body2">
                    Account Created: {formatDate(profileDetails.created_at)}
                  </Typography>
                  <Typography variant="body2">Total Reports (All Time): {profileDetails.report_count}</Typography>
                  
                  <Typography variant="body2">Total Reports (Current Period) {profileDetails.current_period_report_count}</Typography>
                  <Typography variant="body2">Total Pages Reviewed (Current Period): {userPagesCount}</Typography>
                  
                  <hr style={{ border: '1px solid grey', margin: '5px 0' }} />
                  <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                    Subscription Tier: &nbsp;&nbsp;&nbsp;&nbsp;<Chip label={`${profileDetails.subscription_tier}`} color="primary" variant="outlined" />
                    {profileDetails.subscription_tier !== 'Pro' && (
                      <Button variant="contained" size="small" onClick={() => navigate('/pricing')} sx={{ ml: 3 }}>
                        Upgrade
                      </Button>
                    )}
                  </Typography>
                  <hr style={{ border: '1px solid grey', margin: '5px 0' }} />
                  <Typography variant="body2">Reports Remaining (Current Period): {profileDetails.remaining_reports} / {profileDetails.max_reports}</Typography>
                  <Typography variant="body2">Subscription Interval: {profileDetails.subscription_interval}</Typography>
                  <Typography variant="body2">Subscription Start Date: {formatDateShort(profileDetails.subscription_start_date)}</Typography>
                  <Typography variant="body2">Current Period: {formatDateShort(profileDetails.current_period_start)} - {formatDateShort(profileDetails.current_period_end)}</Typography>
                </Paper>

              </Grid>
            </Grid>
            <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
              <Typography variant="h6" gutterBottom>
                Mailing Info
              </Typography>
              {isEditing ? (
                <>
                  <TextField fullWidth margin="normal" name="address_line1" label="Address Line 1" value={mailingInfo.address_line1} onChange={handleInputChange} />
                  <TextField fullWidth margin="normal" name="address_line2" label="Address Line 2" value={mailingInfo.address_line2} onChange={handleInputChange} />
                  <TextField fullWidth margin="normal" name="city" label="City" value={mailingInfo.city} onChange={handleInputChange} />
                  <TextField fullWidth margin="normal" name="state_province" label="State/Province" value={mailingInfo.state_province} onChange={handleInputChange} />
                  <TextField fullWidth margin="normal" name="country" label="Country" value={mailingInfo.country} onChange={handleInputChange} />
                  <TextField fullWidth margin="normal" name="zip_postal_code" label="Zip/Postal Code" value={mailingInfo.zip_postal_code} onChange={handleInputChange} />
                  <Button variant="contained" onClick={saveUserDetails} sx={{ mt: 2 }}>Save</Button>
                  <Button variant="outlined" onClick={() => setIsEditing(false)} sx={{ mt: 2, ml: 2 }}>Cancel</Button>
                </>
              ) : (
                <>
                  <Typography variant="body2">Address Line 1: {mailingInfo.address_line1}</Typography>
                  <Typography variant="body2">Address Line 2: {mailingInfo.address_line2}</Typography>
                  <Typography variant="body2">City: {mailingInfo.city}</Typography>
                  <Typography variant="body2">State/Province: {mailingInfo.state_province}</Typography>
                  <Typography variant="body2">Country: {mailingInfo.country}</Typography>
                  <Typography variant="body2">Zip/Postal Code: {mailingInfo.zip_postal_code}</Typography>
                  <Button variant="contained" onClick={() => setIsEditing(true)} sx={{ mt: 2 }}>Edit</Button>
                </>
              )}
            </Paper>
            <Paper elevation={3} sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>
                Change Password
              </Typography>
              {isOAuthUser ? (
                <Alert severity="info">
                  Password change is not available for accounts linked with OAuth providers (e.g., Google, Facebook).
                </Alert>
              ) : (
                <Box>
                  <TextField
                    label="New Password"
                    type="password"
                    fullWidth
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    sx={{ mb: 2 }}
                  />
                  <Button variant="contained" color="primary" onClick={handleChangePassword}>
                    Update Password
                  </Button>
                </Box>
              )}
            </Paper>
          </>
        )}
      </Box>
    </Container>
  );
};

export default Profile;