import * as React from 'react';
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid'; // Import Grid
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

export default function Hero() {
  const navigate = useNavigate();
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundImage:
          theme.palette.mode === 'light'
            ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
            : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          alignItems: 'center',
          pt: { xs: 10, sm: 10 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Grid container spacing={2} alignItems="center"> {/* Use Grid for layout */}
          <Grid item xs={12} sm={6}> {/* Left side for text */}
            <Stack spacing={3} useFlexGap sx={{ width: '100%', textAlign: 'center' }}> {/* Center text */}
              <Typography
                variant="h1"
                sx={{
                  fontSize: 'clamp(3rem, 4vw, 4rem)', // Adjusted font size for modern look
                  fontWeight: 'bold', // Added bold weight
                }}
              >
                AI-Powered Website Reviews
              </Typography>
              <Typography
                variant="h5"
                color="primary"
                sx={{ mb: 2 }} // Added margin bottom for spacing
              >
                Rapid Reviews | Intelligent Recommendations
              </Typography>
              {/* CTA buttons */}
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                alignSelf="center"
                spacing={2} // Increased spacing for better separation
                useFlexGap
                sx={{ pt: 2, width: { xs: '100%', sm: 'auto' } }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  sx={{ fontWeight: 'bold', py: 1.5 }}
                  onClick={() => navigate('/review')} // Navigate to Review page
                >
                  Try it Free
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  sx={{ fontWeight: 'bold', py: 1.5 }}
                  onClick={() => {
                    // Scroll to Features section
                    setTimeout(() => {
                      const featuresSection = document.getElementById('features');
                      if (featuresSection) {
                        featuresSection.scrollIntoView({ behavior: 'smooth' });
                      }
                    }, 100); // Delay to ensure navigation completes
                  }} // Navigate to Features section
                >
                  Learn more
                </Button>
              </Stack>
              <Typography variant="caption" textAlign="center" sx={{ opacity: 0.8 }}>
                No credit card required to try • No obligation • Cancel anytime
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}> {/* Right side for image placeholder */}
            <Box
              sx={{
                position: 'relative', // Added to position the sticker
                width: '100%',
                height: '100%', // Adjust height as needed
              }}
            >
              <img 
                src={require("../static/img/Example-Screenshot.png")} 
                style={{ 
                  width: '100%', 
                  height: 'auto', 
                  borderRadius: '10px', 
                  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.5)', // Added shadow
                  filter: 'drop-shadow(0 0 10px rgba(25, 118, 210, 1))' // Changed to match primary main color
                }} 
                alt="Example Screenshot" 
              />
              {/* Example sticker */}
              <Box
                sx={{
                  position: 'absolute',
                  top: 10,
                  left: 10,
                  backgroundColor: 'rgba(255, 165, 0, 0.7)', // Orange background with transparency
                  color: 'white',
                  padding: '5px 10px',
                  borderRadius: '5px',
                  fontSize: '12px',
                }}
              >
                Sample Report
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
