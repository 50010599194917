import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const faqData = [
  {
    question: "How do I contact customer support if I have a question or issue?",
    answer: <Typography component="div">
      You can reach our customer support team by emailing <Link> support@sitereviewdesk.ai </Link>. We&apos;re here to assist you promptly.
    </Typography>
  },
  {
    question: "Can I return or cancel my purchase if the service doesn't meet my expectations?",
    answer: <Typography component="div">
      The <strong>Basic Plan</strong> is free for you to try to see if the product meets your expectations. So no cancellations there needed. Thanks for trying us out!
      <Box sx={{ mt: 2 }}>
        If you've purchased one of the <strong>Plus Plan</strong> or <strong>Pro Plan</strong> tiered plans, and you're not completely satisfied, contact us at <Link href="mailto:support@sitereviewdesk.ai">support@sitereviewdesk.ai</Link> and we'll refund you for any unused upcoming month(s) of your subscription and cancel your subscription.
      </Box>
    </Typography>
  },
  {
    question: "What AI models do you use?",
    answer: <Typography component="div">
      We currently use a combination of <strong>Google Gemini AI </strong>✨ multi-modal models to provide comprehensive website analysis as of Sep 9, 2024. As technology evolves, we will continue to evaluate and improve our AI integrations to ensure the best possible results for our users regardless of the specific underlying AI models we use.
    </Typography>
  },
  {
    question: "What is the Overall Score result for a website review?",
    answer: 
      <Box component="div">
        It's a way to summarize the strengths and weaknesses of a website in one number (out of 100). This helps customers quickly understand how their website is performing.
        
        <Box sx={{ mt: 2, mb: 2 }}>
          The overall score (out of 100) is based on a weighted average of 4 categories we analyze for each page within a site:
          
          <ul>
            <li>Visual Score: 20% 🎨</li>
            <li>Content Score: 30% 📄</li>
            <li>Design Score: 30% 🖌️</li>
            <li>Call-To-Action (CTA) Score: 20% 🎯</li>
          </ul>
        </Box>

        We include all relevant crawled pages of the website, with special consideration given to home and/or landing pages. These pages receive double weightage in the calculation.
        
        <Box sx={{ mt: 2 }}>
          The overall score serves as a starting point for discussion with our team about specific improvements needed to elevate your website's quality, effectiveness, and user engagement.
          
          It serves as a simple metric you can use to track KPIs and progress!
        </Box>
      </Box>
  },
  {
    question: "Are you able to review sites that are protected by Cloudflare or similar services?",
    answer: <>At this time, we do not support the review of sites that are protected by Cloudflare or similar anti-bot and anti-crawling services. This currently affects about 1/5th of all hosted websites out there. We respect the intent of these security measures and do not currently have plans to support such sites or apply any workarounds.
      <br /><br />
      We will continue to monitor demand for this feature and may update our product with an ownership verification framework if there is significant demand for it.</>
  },
  {
    question: "How does the tool analyze links on a website?",
    answer: 
      <Box component="div">
        Our tool analyzes all links found on the pages of your website. This includes both internal links (links to other pages within your site) and external links (links to other websites). However, to ensure efficient and relevant analysis, we have some limitations:
        <Box sx={{ mt: 2, mb: 2 }}>
          <Typography variant="subtitle2" component="div">We don't analyze links that use these protocols:</Typography>
          <ul>
            <li>mailto: (email links)</li>
            <li>ftp: and sftp: (file transfer protocols)</li>
            <li>telnet: and ssh: (remote access protocols)</li>
          </ul>
        </Box>
        
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" component="div">We also don't analyze links to files with these extensions:</Typography>
          <ul>
            <li>Document files: .pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx</li>
            <li>Image files: .jpg, .png, .gif</li>
            <li>Video files: .mov, .mp4</li>
            <li>Compressed files: .zip, .rar, .gz</li>
            <li>Disk image files: .dmg</li>
          </ul>
        </Box>

        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" component="div">Additionally, we skip crawling links to major social media platforms, including:</Typography>
          <ul>
            <li>Facebook.com</li>
            <li>Instagram.com</li>
            <li>LinkedIn.com</li>
            <li>Twitter.com</li>
            <li>YouTube.com</li>
            <li>X.com</li>
          </ul>
        </Box>

        This approach allows us to focus on the core web pages of your site, providing the most relevant and actionable insights for your website's structure and content. By excluding social media platforms and certain file types, we can concentrate on analyzing the main content and structure of your website without getting sidetracked by external platforms or downloadable content.
      </Box>
  }
];

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box
      id="pricing"
      sx={{
        color: 'white',
        bgcolor: '#06090a',
      }}
    >
      <Container
        id="faq"
        sx={{
          pt: 6,
          pb: 8,
          xs: 8,
          sm: 16,
          color: 'white',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          color="text.primary"
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          Frequently Asked Questions
        </Typography>
        <Box sx={{ width: '80%' }}>
          {faqData.map((faq, index) => (
            <Accordion
              key={index}
              expanded={expanded === `panel${index + 1}`}
              onChange={handleChange(`panel${index + 1}`)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index + 1}d-content`}
                id={`panel${index + 1}d-header`}
              >
                <Typography component="h3" variant="subtitle2">
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ maxWidth: { sm: '100%', md: '70%' } }}>
                  {faq.answer}
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Container>
    </Box>
  );
}
