import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Slider from "react-slick";
import ExamplePerPageAssessments from '../static/img/Example-Per-Page-Assessments.png';
import ExampleRecommendations from '../static/img/Example-Recommendations.png';
import ExampleVisualAnalysis from '../static/img/Example-VisualAnalysis.png';
import ExampleCTAOptimization from '../static/img/Example-CTA-Optimization.png';
import ExampleExecutiveSummary from '../static/img/Example-Executive-Summary.png';
import ExampleComprehensiveAnalysis from '../static/img/Example-Comprehensive-Analysis.png';
import ExampleReportsInMinutes from '../static/img/Example-Reports-In-Minutes.png';

// Import css files for react-slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AssessmentIcon from '@mui/icons-material/Assessment';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import SpeedIcon from '@mui/icons-material/Speed';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import PageviewIcon from '@mui/icons-material/Pageview';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import SummarizeIcon from '@mui/icons-material/Summarize';
import VisibilityIcon from '@mui/icons-material/Visibility';

const items = [
  {
    icon: <AssessmentIcon />,
    title: 'AI-Powered Insights',
    description:
      'Leverage advanced AI technology to analyze your website\'s content and deliver expert-level scoring! We continuously integrate the latest AI tools to reveal valuable insights and optimize recommendations, ensuring you maximize your website\'s potential.',
    image: ExampleComprehensiveAnalysis,
  },
  {
    icon: <AnalyticsIcon />,
    title: 'Comprehensive Website Analysis',
    description:
      'Get a detailed examination of your website\'s text and visual content, design elements, call-to-action (CTA) effectiveness, and SEO potential. Our analysis covers multiple pages (up to 50 for Pro tier) to give you a complete picture of your site\'s performance.',
    image: ExampleComprehensiveAnalysis,
  },
  {
    icon: <SpeedIcon />,
    title: 'Reports in Minutes',
    description:
      'Wave farewell to those endless meetings and consultations! Get your detailed reports in mere minutes, not weeks—it\'s like having a personal website consultant ready to help you with your site anytime!',
    image: ExampleReportsInMinutes,
  },
  {
    icon: <PriorityHighIcon />,
    title: 'Prioritized Recommendations',
    description:
      'Receive actionable recommendations categorized by high, medium, and low impact and effort -- helping you focus on the most critical improvements first, maximizing your ROI and efficiency.',
    image: ExampleRecommendations,
  },
  {
    icon: <PageviewIcon />,
    title: 'Per-Page Assessments',
    description:
      'Get a detailed analysis of each page, with examples and easy-to-understand multi-category scores and recommendations for improvement.',
    image: ExamplePerPageAssessments,
  },
  {
    icon: <TouchAppIcon />,
    title: 'CTA Optimization',
    description:
      'Receive a detailed analysis of your Call-to-Action elements across all pages. Get recommendations for improving conversion potential and evaluating CTA placement and effectiveness.',
    image: ExampleCTAOptimization,
  },
  {
    icon: <SummarizeIcon />,
    title: 'Executive Summary',
    description:
      'Access a concise overview of key findings and priorities. Get quick-glance scores for overall website performance and highlights of critical areas for improvement.',
    image: ExampleExecutiveSummary,
  },
  {
    icon: <VisibilityIcon />,
    title: 'Visual Analysis',
    description:
      'Benefit from screenshot capture of crawled pages and a visual breakdown of your website\'s design elements. Identify design strengths and weaknesses to improve user experience.',
    image: ExampleVisualAnalysis,
  },
];

export default function Features() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    fade: true,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          autoplay: false,
        }
      }
    ],
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  // Custom arrow components
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", right: "1px" }}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", left: "1px", zIndex: 1 }}
        onClick={onClick}
      />
    );
  }

  return (
    <Box
      id="features"
      sx={{
        color: 'white',
        bgcolor: 'background.default',
        py: { xs: 4, md: 6 }, // Reduced padding on mobile
        mb: { xs: 4, md: 6 }, // Reduced margin on mobile
        ml: { xs: 2, md: 6 }, // Reduced left margin on mobile
        mr: { xs: 2, md: 6 }, // Reduced right margin on mobile
        '& .slick-prev, & .slick-next': {
          width: '20px',
          height: '20px',
          '&:before': {
            fontSize: '25px',
          },
        },
        '& .slick-prev': {
          left: '-30px',
        },
        '& .slick-next': {
          right: '-30px',
        },
      }}
    >
      <Container maxWidth="lg">
        <Typography
          component="h2"
          variant="h4"
          color="text.primary"
          textAlign="center"
          mb={{ xs: 4, md: 6 }} // Reduced margin on mobile
        >
          Features
        </Typography>
        <Box sx={{
          '.slick-dots': {
            bottom: { xs: -30, md: -50 }, // Adjusted dots position for mobile
            '& li button:before': {
              fontSize: { xs: 8, md: 12 }, // Smaller dots on mobile
              color: 'primary.main'
            },
          }
        }}>
          <Slider {...settings}>
            {items.map((item, index) => (
              <Box key={index} sx={{ px: { xs: 0, md: 2 } }}>
                <Card sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' }, // Row layout for desktop, column for mobile
                  height: { xs: 'auto', md: '400px' }, // Fixed height for desktop, auto for mobile
                  bgcolor: 'background.default', // Changed from 'black' to 'background.default'
                  overflow: 'hidden',
                  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                  boxShadow: 'none', // Remove box shadow
                }}>
                  <CardContent sx={{
                    width: { xs: '100%', md: '50%' }, // Full width on mobile, 50% on desktop
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    p: { xs: 2, md: 4 },
                  }}>
                    <Box sx={{
                      alignItems: 'center',
                      mb: 2,
                      mt: 2,
                      border: '1px solid',
                      borderColor: 'grey.700',
                      borderRadius: '4px',
                      bgcolor: 'background.default', // Changed from 'black' to 'background.default'
                      p: { xs: 1, md: 2 }, // Reduced padding on mobile
                      boxShadow: 'none', // Remove box shadow
                    }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                        <Box sx={{
                          mr: 2,
                          color: 'primary.main',
                          '& svg': { fontSize: 40 },
                          borderRadius: '4px',
                          bgcolor: 'grey.900',
                          p: 2,
                        }}>
                          {item.icon}
                        </Box>
                        <Typography variant="h5" component="h3">
                          {item.title}
                        </Typography>
                      </Box>
                      <Typography variant="body1" color="text.secondary" lineHeight={1.8}>
                        {item.description}
                      </Typography>
                    </Box>
                  </CardContent>
                  <Box
                    sx={{
                      width: { xs: '100%', md: '50%' }, // Full width on mobile, 50% on desktop
                      height: { xs: 200, md: '100%' }, // Fixed height on mobile, full height on desktop
                      overflow: 'hidden',
                    }}
                  >
                    <img
                      src={item.image}
                      alt={item.title}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'scale-down',
                        boxShadow: '0 0 20px 10px rgba(128, 128, 128, 0.3)', // Updated grey drop shadow glow
                      }}
                    />
                  </Box>
                </Card>
              </Box>
            ))}
          </Slider>
        </Box>
      </Container>
    </Box>
  );
}
