import React from 'react';
import { Typography, Container, Link, Divider, Paper } from '@mui/material';
import Footer from '../components/Footer';

const Privacy = () => {
    return (
        <>
            <Container maxWidth="md" sx={{ pt: 8, pb: 8 }}>
                <Typography variant="h5" component="h1" gutterBottom>
                    Privacy Policy
                </Typography>
                <Paper
                    elevation={3}
                    sx={{
                        maxHeight: 'calc(100vh - 250px)',
                        overflowY: 'auto',
                        p: 4,
                        mb: 4
                    }}
                >
                    <Typography variant="body2" paragraph>
                        Last updated: Sep 9, 2024
                    </Typography>
                    <Typography variant="body2" paragraph>
                        At SiteReviewDesk.ai, we value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and safeguard your data when you use our website and services.
                    </Typography>

                    <Typography variant="h7" gutterBottom>
                        1. Information We Collect
                    </Typography>
                    <Typography variant="body2" paragraph>
                        We may collect personal information such as your name, email address, and website URL when you register for our services or contact us for support.
                    </Typography>

                    <Typography variant="h7" gutterBottom>
                        2. How We Use Your Information
                    </Typography>
                    <Typography variant="body2" paragraph>
                        We use your information to provide and improve our services, communicate with you, and ensure the security of our platform.
                    </Typography>

                    <Typography variant="h7" gutterBottom>
                        3. Data Protection
                    </Typography>
                    <Typography variant="body2" paragraph>
                        We implement industry-standard security measures to protect your personal information from unauthorized access, disclosure, or misuse.
                    </Typography>

                    <Typography variant="h7" gutterBottom>
                        4. Third-Party Services
                    </Typography>
                    <Typography variant="body2" paragraph>
                        We may use third-party services to help us operate our website and provide our services. These services may have access to your personal information only to perform specific tasks on our behalf.
                    </Typography>

                    <Typography variant="h7" gutterBottom>
                        5. Your Rights
                    </Typography>
                    <Typography variant="body2" paragraph>
                        You have the right to access, correct, or delete your personal information. Please contact us if you wish to exercise these rights.
                    </Typography>

                    <Typography variant="h7" gutterBottom>
                        6. Changes to This Policy
                    </Typography>
                    <Typography variant="body2" paragraph>
                        We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
                    </Typography>

                    <Typography variant="h7" gutterBottom>
                        7. Contact Us
                    </Typography>
                    <Typography variant="body2" paragraph>
                        If you have any questions about this Privacy Policy, please contact us at{' '}
                        <Link href="mailto:privacy@sitereviewdesk.ai">privacy@sitereviewdesk.ai</Link>.
                    </Typography>
                </Paper>
            </Container>
            <Divider />
            <Footer />
        </>
    );
};

export default Privacy;