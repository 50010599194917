import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, Typography, Button, Box } from '@mui/material'; // Import necessary components
import { useAuth } from '../AuthContext'; // Import useAuth
import CircularProgress from '@mui/material/CircularProgress'; // Import CircularProgress

function SubscriptionResult() {
  const { currentUser } = useAuth(); // Get current user
  const [status, setStatus] = useState('loading');
  const [error, setError] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const sessionId = queryParams.get('session_id');
    const userId = queryParams.get('user_id');
    const errorMessage = queryParams.get('message');

    if (errorMessage) {
      setStatus('error');
      setError(decodeURIComponent(errorMessage));
    } else if (sessionId && userId) {
      // Fetch updated user info
      axios.get(`/api/v1/user_info?user_id=${userId}`)
        .then(response => {
          setUserInfo(response.data);
          setStatus('success');
        })
        .catch(error => {
          setStatus('error');
          setError(error.response?.data?.message || 'An error occurred while fetching user information');
        });
    } else {
      setStatus('error');
      setError('Missing session information');
    }
  }, [location, currentUser, navigate]); // Add currentUser and navigate to dependencies

  if (status === 'loading') {
    return (
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 12 },
          pb: { xs: 8, sm: 8 },
        }}>
        <Box sx={{ mb: 2 }}>
          <Typography variant="body1">Verifying your subscription...</Typography>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (status === 'error') {
    return (
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 12 },
          pb: { xs: 8, sm: 8 },
        }}>
        <Typography variant="h4" color="error" gutterBottom>Subscription Error</Typography>
        <Box sx={{ mb: 2 }}>
          <Typography variant="body1">An error occurred: {error}</Typography>
        </Box>
        <Typography variant="body2" gutterBottom>
          Please try again or contact our support team if the problem persists.
        </Typography>
        <Button variant="contained" onClick={() => navigate('/')} sx={{ mt: 2 }}>Go to Home Page</Button>
      </Container>
    );
  }

  const getPlanDetails = () => {
    if (!userInfo) return null;

    return {
      subscription_tier: userInfo.subscription_tier,
      max_reports: userInfo.max_reports,
      max_pages_per_report: userInfo.max_pages_per_report,
      max_depth: userInfo.max_depth,
      max_recommendations: userInfo.max_recommendations,
      subscription_interval: userInfo.subscription_interval,
      current_period_start: userInfo.current_period_start,
      current_period_end: userInfo.current_period_end,
    };
  };

  const planDetails = getPlanDetails();

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pt: { xs: 14, sm: 12 },
        pb: { xs: 8, sm: 8 },
      }}>
      <Typography variant="h4">Subscription Successful ✅</Typography>
      <Box sx={{ mb: 2 }} />
      <Typography variant="body1">
        Thank you for subscribing to our <Box component="span" sx={{ color: 'yellow' }}>{planDetails.subscription_tier}</Box> plan. You now have access to premium features:
      </Typography>
      {planDetails ? (
        <>
          <ul>
            <li><Box component="span" sx={{ color: 'yellow' }}>{planDetails.max_reports}</Box> report runs per {planDetails.subscription_interval}</li>
            <li>Up to <Box component="span" sx={{ color: 'yellow' }}>{planDetails.max_pages_per_report}</Box> pages per report</li>
            <li>Maximum link depth of <Box component="span" sx={{ color: 'yellow' }}>{planDetails.max_depth}</Box></li>
            <li>Up to <Box component="span" sx={{ color: 'yellow' }}>{planDetails.max_recommendations}</Box> recommendations for improvements per report</li>
          </ul>
        </>
      ) : (
        <Typography variant="body1">Thank you for subscribing. You now have access to premium features.</Typography>
      )}
      <Button variant="contained" onClick={() => navigate('/')} sx={{ mt: 2 }}>Go to Home Page</Button>
    </Container>
  );
}

export default SubscriptionResult;