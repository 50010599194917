import React from 'react';
import AppAppBar from '../components/AppAppBar';
import { Box, Container, Toolbar } from '@mui/material';

const Header = () => {
  return (
    <Container>
      <AppAppBar />
      <Toolbar>
        <Box
          sx={{ height: 64, backgroundColor: 'transparent' }}
        >
        </Box>
      </Toolbar>
    </Container>
  );
};

export default Header;