import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';

const items = [
  {
    icon: <SettingsSuggestRoundedIcon />,
    title: 'AI-Powered Analysis',
    description:
      'We use advanced AI technology to thoroughly analyze your website, focusing on key areas like content quality, call-to-action effectiveness, visual appeal, and overall design. Our AI system is regularly updated with the latest industry trends, helping you stay ahead effortlessly. ✨',
  },
  {
    icon: <ConstructionRoundedIcon />,
    title: 'Comprehensive Reports',
    description:
      'Our Site Reports compile insights, per page screenshots, visual analysis, content reviews, and recommendations into structured reports for you. We also provide summaries for quick reference. 📊',
  },
  {
    icon: <ThumbUpAltRoundedIcon />,
    title: 'Prioritized Recommendations',
    description:
      'Receive tailored recommendations based on comprehensive analysis results to improve your website. We provide a categorized list of recommendations with low, medium, and high priority ratings for level of effort and impact to help you prioritize your efforts. ✅',
  },
  {
    icon: <AutoFixHighRoundedIcon />,
    title: 'Per-Page Assessments',
    description:
      'Explore detailed insights for each individual page, showcasing comprehensive analyses and tailored recommendations from our report runs. Effortlessly revisit past reports to monitor your progress and observe the evolution of your website over time. 📄',
  },
  {
    icon: <SupportAgentRoundedIcon />,
    title: 'Reports in Minutes',
    description:
      'Our AI-driven platform generates reports autonomously, providing you with insights to view within minutes instead of days or weeks! Entering the URL and clicking the start button is all it takes! 🚀',
  },
  {
    icon: <QueryStatsRoundedIcon />,
    title: 'Mobile-Friendly UI',
    description:
      'Our UI is designed to be mobile-friendly, so you can initiate and access your reports on the go. No need to download anything, just open the URL and you\'re ready to go! 📱',
  },
]

export default function Highlights() {
  return (
    <Box
      id="highlights"
      sx={{
        pt: 6,
        pb: 8,
        xs: 8,
        sm: 16,
        color: 'white',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4">
            Highlights
          </Typography>
          <Typography variant="body1" sx={{ color: 'grey.400' }}>
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Stack
                direction="column"
                color="inherit"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  p: 3,
                  height: '100%',
                  border: '1px solid',
                  borderColor: 'grey.800',
                  background: 'transparent',
                  backgroundColor: 'grey.900',
                }}
              >
                <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                <div>
                  <Typography fontWeight="medium" gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'grey.400' }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
