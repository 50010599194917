import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../config';
import { Button, Box, Select, MenuItem, FormControl, InputLabel, Alert, CircularProgress, Container, Typography, Paper } from '@mui/material';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import TerminalProgress from './TerminalProgress';
import axios from 'axios';
import { styled } from '@mui/material/styles';

const DropZone = styled(Box)(({ theme, isDragging, disabled }) => ({
  border: `2px dashed ${theme.palette.primary.main}`,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: isDragging ? theme.palette.action.hover : 'transparent',
  padding: theme.spacing(3),
  textAlign: 'center',
  cursor: disabled ? 'not-allowed' : 'pointer',
  transition: 'all 0.3s ease-in-out',
  opacity: disabled ? 0.5 : 1,
  '&:hover': {
    backgroundColor: disabled ? 'transparent' : theme.palette.action.hover,
  },
}));

const ReviewForm = () => {
  const { currentUser } = useAuth();
  const [profileDetails, setProfileDetails] = useState({});
  const [authError, setAuthError] = useState(false);
  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState('');
  const [llm, setLlm] = useState('gemini1.5flash');
  const [report_level, setReportLevel] = useState('Basic');
  const [loading, setLoading] = useState(false);
  const [crawlStatus, setCrawlStatus] = useState([]);
  const [isCrawling, setIsCrawling] = useState(false);
  const [progress, setProgress] = useState(0);
  const [subscriptionError, setSubscriptionError] = useState('');
  const [generalError, setGeneralError] = useState('');
  const navigate = useNavigate();
  const [isDragging, setIsDragging] = useState(false);

  const isDevEnvironment = process.env.REACT_APP_ENV === 'dev';


  // ========================================================================== //
  //   Get the user's profile details from the database
  // ========================================================================== //

  useEffect(() => {
    if (!currentUser) {
      setAuthError(true); // Set authError if no user is authenticated
      return;
    }
    setAuthError(false); // Clear authError if user is authenticated
    const fetchProfileDetails = async () => {
      if (currentUser) {
        try {
          const response = await axios.get(`${API_BASE_URL}/api/v1/users/${currentUser.uid}`);
          const userData = response.data;
          setProfileDetails(userData);
        } catch (error) {
          console.error('Error fetching profile details:', error);
        }
      }
    };

    fetchProfileDetails();
  }, [currentUser]);


  // ========================================================================== //
  //   Set the default report_level based on the user's subscription tier
  // ========================================================================== //

  useEffect(() => {
    setReportLevel(profileDetails?.subscription_tier || 'Basic');
  }, [profileDetails]);


  const validateFile = (file) => {
    if (!file) {
      setFileError('Please select a file');
      return false;
    }

    const validTypes = ['image/jpeg', 'image/png'];
    if (!validTypes.includes(file.type)) {
      setFileError('Please upload a valid PNG or JPEG image');
      return false;
    }

    if (file.size > 10 * 1024 * 1024) { // 10MB limit
      setFileError('File size must be less than 10MB');
      return false;
    }

    setFileError('');
    return true;
  };

  // ========================================================================== //
  //   Handle the form submission
  // ========================================================================== //

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateFile(file)) {
      return;
    }

    setLoading(true);
    setIsCrawling(true);
    setCrawlStatus([]);
    setProgress(0);
    setSubscriptionError('');
    setGeneralError('');

    const formData = new FormData();
    formData.append('image', file);
    formData.append('llm', llm);
    formData.append('report_level', report_level);
    formData.append('user_id', currentUser.uid);

    try {
      // const response = await axios.post(`${API_BASE_URL}/api/v1/manualuploadreview`, formData);
      const response = await axios.post(`${API_BASE_URL}/api/v1/manualuploadreview`, formData);

      console.log('response', response);
      // Set up the EventSource to receive status updates
      const eventSource = new EventSource(
        `${API_BASE_URL}/api/v1/crawl_status?report_id=${response.data.report_id}&user_id=${currentUser.uid}`
      );

      eventSource.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log('Received event:', data);

        setCrawlStatus(prevStatus => [...prevStatus, data]);

        // Update progress based on manual review status messages
        switch (data.status.toLowerCase()) {
          case 'started':
            setProgress(10);
            break;
          case 'in progress':
            setProgress(20);
            break;
          case 'vision llm analyzing screenshot':
            setProgress(40);
            break;
          case 'llm executive summary':
            setProgress(60);
            break;
          case 'generating final report':
            setProgress(80);
            break;
          case 'completed':
            setProgress(100);
            eventSource.close();
            setIsCrawling(false);
            navigate(`/report?report_id=${response.data.report_id}&type=manual_review&llm=${llm}&report_level=${report_level}`);
            break;
          case 'error':
            setGeneralError('An error occurred during the review process. ' + (data.message || ''));
            setLoading(false);
            setIsCrawling(false);
            eventSource.close();
            break;
          default:
            console.log(`Unhandled status: ${data.status}`);
            break;
        }
      };

    } catch (error) {
      if (error.response && error.response.status === 403) {
        setSubscriptionError('You have reached the report limit for your subscription tier.');
      } else {
        console.error('Error starting review:', error);
        setGeneralError('An error occurred while starting the review. Please try again.');
      }
      setLoading(false);
      setIsCrawling(false);
    }
  };


  const handleUpgradeClick = () => {
    navigate('/pricing');
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    if (loading || isCrawling || profileDetails.remaining_reports === 0) return;

    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      setFile(droppedFile);
      validateFile(droppedFile);
    }
  };

  return (
    <Container>
      <Paper
        elevation={0}
        sx={{
          mt: 3,
          mb: 3,
          p: 2,
          borderRadius: 2,
          border: '1px solid',
          borderColor: 'grey.200',
          backgroundColor: 'grey.80',
          width: '100%',
          maxWidth: 600,
        }}
      >
        <Typography variant="h6" sx={{ mb: 1, color: 'primary.main' }}>
          Single Page Review
        </Typography>
        <Typography variant="body1" sx={{ mb: 1 }}>
          Upload a screenshot of any webpage you want to analyze. For best results:
        </Typography>
        <Box component="ul" sx={{ m: 0, pl: 3 }}>
          <Typography component="li" variant="body2" sx={{ mb: 0.5 }}>
            Capture only the page content (avoid browser menus, tabs, and toolbars)
          </Typography>
          <Typography component="li" variant="body2" sx={{ mb: 0.5 }}>
            Include the full page width
          </Typography>
          <Typography component="li" variant="body2">
            Save as PNG or JPEG format
          </Typography>
        </Box>
      </Paper>

      {authError && (
        <Alert severity="warning" sx={{ mb: 2 }}>
          You need to log in or create an account to run a review.{' '}
          <Link to="/login" style={{ color: '#58dbef', textDecoration: 'underline' }}>
            Click here to log in
          </Link>
        </Alert>
      )}

      {subscriptionError && (
        <Alert
          severity="warning"
          sx={{ mb: 2, width: '100%', maxWidth: 600 }}
          action={
            <Button color="inherit" size="small" onClick={handleUpgradeClick} variant="contained">
              Upgrade
            </Button>
          }
        >
          {subscriptionError} Please upgrade your subscription to continue.
        </Alert>
      )}
      {generalError && (
        <Alert severity="error" sx={{ mb: 2, width: '100%', maxWidth: 600 }}>
          {generalError}
        </Alert>
      )}
      <>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: '100%', maxWidth: 600 }}>
          {profileDetails.remaining_reports === 0 && (
            <Alert severity="warning" sx={{ mb: 2 }}>
              You've reached the maximum number of reports runs you can run this month for your subscription tier.
            </Alert>
          )}
          {profileDetails.remaining_reports === 0 && profileDetails.subscription_tier !== 'Pro' && (
            <Box display="flex" justifyContent="center" mt={2} mb={2}>
              <Button variant="contained" size="small" onClick={() => navigate('/pricing')}>
                Upgrade
              </Button>
            </Box>
          )}
          <input
            type="file"
            name="file"
            id="file-input"
            accept="image/png,image/jpeg"
            hidden
            onChange={(e) => {
              const selectedFile = e.target.files[0];
              setFile(selectedFile);
              validateFile(selectedFile);
            }}
          />
          {fileError && (
            <Typography color="error" variant="body2" sx={{ mt: 1 }}>
              {fileError}
            </Typography>
          )}
          <label htmlFor="file">
            Choose a file:
            <DropZone
              isDragging={isDragging}
              disabled={loading || isCrawling || profileDetails.remaining_reports === 0}
              onDragEnter={handleDragIn}
              onDragLeave={handleDragOut}
              onDragOver={handleDrag}
              onDrop={handleDrop}
              onClick={(e) => {
                e.preventDefault();
                if (loading || isCrawling || profileDetails.remaining_reports === 0) return;
                const fileInput = document.getElementById('file-input');
                fileInput.click();
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
                <Typography variant="body1" color="primary.main">
                  {isDragging ? 'Drop your image here' : 'Drag and drop your image here, or click to select'}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Supported formats: PNG, JPEG (max 10MB)
                </Typography>
                {file && (
                  <Typography variant="body2" color="primary">
                    Selected: {file.name}
                  </Typography>
                )}
              </Box>
            </DropZone>
          </label>
          {isDevEnvironment && (
            <FormControl fullWidth margin="normal">
              <InputLabel id="llm-select-label">Select LLM</InputLabel>
              <Select
                labelId="llm-select-label"
                id="llm-select"
                value={llm}
                label="Select LLM"
                onChange={(e) => setLlm(e.target.value)}
                disabled={loading || isCrawling || profileDetails.remaining_reports === 0}
              >
                <MenuItem value="chatgpt4.0mini">ChatGPT 4o-mini</MenuItem>
                <MenuItem value="sonnet3.5">Claude Sonnet 3.5</MenuItem>
                <MenuItem value="gemini1.5flash">Gemini 1.5 Flash</MenuItem>
                <MenuItem value="ollama">Ollama</MenuItem>
              </Select>
            </FormControl>
          )}
          <FormControl fullWidth margin="normal">
            <InputLabel id="report-level-select-label" >Report Level</InputLabel>
            <Select
              labelId="report-level-select-label"
              id="report-level-select"
              value={report_level}
              label="Report Level"
              onChange={(e) => setReportLevel(e.target.value)}
              disabled={loading || isCrawling || profileDetails.remaining_reports === 0}
            >
              <MenuItem value="Basic">Basic</MenuItem>
              <MenuItem value="Plus" disabled={!['Plus', 'Pro'].includes(profileDetails.subscription_tier)}>
                Plus {!['Plus', 'Pro'].includes(profileDetails.subscription_tier) && '(requires upgrade)'}
              </MenuItem>
              <MenuItem value="Pro" disabled={profileDetails.subscription_tier !== 'Pro'}>
                Pro {profileDetails.subscription_tier !== 'Pro' && '(requires upgrade)'}
              </MenuItem>
            </Select>
          </FormControl>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={loading || isCrawling || profileDetails.remaining_reports === 0}
          >
            {loading || isCrawling ? (
              <CircularProgress size={24} sx={{ color: 'white', mr: 1 }} />
            ) : 'Start Review'}
          </Button>
        </Box>

        {isCrawling && (
          <>
            <Paper
              elevation={0}
              sx={{
                mt: 2,
                mb: 2,
                p: 2,
                borderRadius: 2,
                border: '1px solid',
                color: 'grey.800',
                width: '100%',
                maxWidth: 600, // Match the maxWidth of the form
              }}
            >
              <Typography variant="body1" color="info.dark">
                Your review is underway! It typically takes 2-5 minutes to complete.
                Feel free to watch the progress below or check your report later in the{' '}
                <Link to="/dashboard" style={{ color: '#58dbef', textDecoration: 'underline' }}>
                  Dashboard
                </Link>.
              </Typography>
            </Paper>
            <Box
              mt={4}
              width="100%"
              maxWidth={600}
              sx={{
                opacity: 0,
                transition: 'opacity 0.5s ease-in',
                '&.visible': {
                  opacity: 1,
                },
              }}
              className="visible"
            >
              <TerminalProgress progress={progress} events={crawlStatus} />
            </Box>
          </>
        )}
      </>
    </Container>
  );
};

export default ReviewForm;