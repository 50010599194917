import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const PrivateRoute = ({ element }) => {
  const { currentUser } = useAuth();

  if (currentUser) {
    return element;
  } else {
    // Pass a state object with the message to be displayed on the login page.
    return <Navigate to="/login" state={{ from: window.location.pathname, message: 'Please sign in to access this page: ' }} />;
  }
};

export default PrivateRoute;
