import React, { useEffect, useRef } from 'react';
import mermaid from 'mermaid';

const MermaidDiagram = ({ diagram }) => {
  const mermaidRef = useRef(null);

  useEffect(() => {
    mermaid.initialize({
      startOnLoad: true,
      theme: 'dark',
      securityLevel: 'loose',
    });

    const renderDiagram = async () => {
      if (mermaidRef.current) {
        mermaidRef.current.innerHTML = diagram;
        await mermaid.run({
          nodes: [mermaidRef.current],
        });
      }
    };

    renderDiagram();
  }, [diagram]);

  return <div className="mermaid" ref={mermaidRef} />;
};

export default MermaidDiagram;