import Features from '../components/Features';
import Footer from '../components/Footer';
import { Box, Divider } from '@mui/material';


export default function FeaturesPage() {
    return (
        <Box sx={{ bgcolor: 'background.default' }}>
            <Features />
            <Divider />
            <Footer />
            
        </Box>
    );
}
