import React from 'react';
import { Typography, Box, Container, Divider, Link } from '@mui/material';
import Footer from '../components/Footer';

const Press = () => {
    return (
        <>
            <Container maxWidth="md" sx={{ pt: 8, pb: 8 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Press Release
                </Typography>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
                    SiteReviewDesk.ai Launches Revolutionary AI-Powered Website Analysis Tool
                </Typography>
                <Typography variant="body1" paragraph>
                    For Immediate Release - 2024-09-13
                </Typography>

                <Typography variant="body1" paragraph>
                    <Link href="https://www.sitereviewdesk.ai" target="_blank" rel="noopener noreferrer">
                        SiteReviewDesk.ai
                    </Link>, a cutting-edge AI-powered website analysis tool, announces its official launch today. This innovative platform is set to revolutionize how businesses and professionals optimize their online presence by providing instant, comprehensive website analysis.
                </Typography>

                <Typography variant="body1" paragraph>
                    Founded by Alvin Wong, a former Cisco and OpenDNS engineer, SiteReviewDesk.ai leverages advanced AI and Large Language Models (LLMs) to automate the complex task of website analysis. The platform generates detailed reports on content, structure, and SEO potential in minutes, offering a significant advantage over traditional, time-consuming consulting processes.
                </Typography>

                <Typography variant="body1" paragraph>
                    "At SiteReviewDesk.ai, we're putting AI to work in a practical, tangible way," explains Alvin S. Wong, Founder and CEO. "Whether you're a busy content consultant, a marketing agency juggling multiple clients, or an individual website owner, our tool simplifies the complex task of website analysis. We're not just applying AI for the sake of it – we're using it to save time, reduce stress, and empower people to make data-driven decisions about their online presence."
                </Typography>

                <Typography variant="body1" paragraph>
                    Key features of SiteReviewDesk.ai include:
                </Typography>

                <ul>
                    <li>Rapid website crawling and AI-powered content analysis</li>
                    <li>Comprehensive reports on website content, call to actions, visual appeal, design, and SEO potential</li>
                    <li>Actionable recommendations for improving marketing and lead generation with prioritized ROI ratings</li>
                    <li>Review screenshot captures of crawled pages for a page-by-page analysis</li>
                    <li>User-friendly dashboard for report management and trend reviews including catetegorical scores</li>
                    <li>Continual integration and application of AI models for cutting-edge analysis</li>
                    <li>Tiered subscription model to suit various needs of individuals and businesses (including a free tier)</li>
                </ul>

                <Typography variant="body1" paragraph>
                    The launch of SiteReviewDesk.ai comes at a time when businesses are increasingly recognizing the importance of a strong online presence. With its ability to provide quick, in-depth analysis, SiteReviewDesk.ai is positioned to become an essential tool for entrepreneurs, content consultants, and marketers alike.
                </Typography>

                <Typography variant="body1" paragraph>
                    For more information about SiteReviewDesk.ai or to schedule a demo, please visit{' '}
                    <Link href="https://www.sitereviewdesk.ai" target="_blank" rel="noopener noreferrer">
                        www.sitereviewdesk.ai
                    </Link> or contact{' '}
                    <Link href="mailto:press@sitereviewdesk.ai">
                        press@sitereviewdesk.ai
                    </Link>.
                </Typography>

                <Divider sx={{ my: 4 }} />

                <Typography variant="h6" gutterBottom>
                    About SiteReviewDesk.ai
                </Typography>
                <Typography variant="body1" paragraph>
                    SiteReviewDesk.ai is an AI-powered website analysis tool designed to help businesses and professionals optimize their online presence. By leveraging cutting-edge AI technology, SiteReviewDesk.ai provides instant, comprehensive website analysis, generating detailed reports on content, structure, and SEO potential in minutes.
                </Typography>

                <Box sx={{ mt: 6, textAlign: 'center' }}>
                    <Typography variant="h6" gutterBottom>Media Contact</Typography>
                    <Typography variant="body1">
                        Name: Alvin S. Wong<br />
                        Email: <Link href="mailto:press@sitereviewdesk.ai">press@sitereviewdesk.ai</Link><br />
                    </Typography>
                </Box>

            </Container>
            <Divider />
            <Footer />
        </>
    );
};

export default Press;