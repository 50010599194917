import React, { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { API_BASE_URL } from '../config'; // Import the API base URL
import { Box, Typography, Button } from '@mui/material';
import { formatDate } from '../components/FormatDates';

const DebugPage = () => {
  const [user, setUser] = useState(null);
  const [reports, setReports] = useState([]);
  const [images, setImages] = useState([]);
  const [firebaseConfig, setFirebaseConfig] = useState({});
  const auth = getAuth();

  useEffect(() => {
    // Fetch user profile info
    const currentUser = auth.currentUser;
    if (currentUser) {
      setUser({
        uid: currentUser.uid,
        email: currentUser.email,
        display_name: currentUser.displayName,
      });
    }

    // Fetch reports from Flask API
    const fetchReports = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/v1/reports?user_id=${currentUser.uid}`);
        if (!response.ok) throw new Error('Failed to fetch reports');
        const reportList = await response.json();
        setReports(reportList);
      } catch (error) {
        console.error("Error fetching reports: ", error);
        setReports([]); // Clear reports on error
      }
    };

    // Fetch images from Flask API
    const fetchImages = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/v1/reports/${currentUser.uid}/pages`);
        if (!response.ok) throw new Error('Failed to fetch images');
        const imageList = await response.json();
        setImages(imageList);
      } catch (error) {
        console.error("Error fetching images: ", error);
        setImages([]); // Clear images on error
      }
    };

    // Fetch Firebase configuration from Flask API
    const fetchFirebaseConfig = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/v1/firebase_config`);
        if (!response.ok) throw new Error('Failed to fetch Firebase config');
        const config = await response.json();
        setFirebaseConfig(config);
      } catch (error) {
        console.error("Error fetching Firebase config: ", error);
        setFirebaseConfig({}); // Clear config on error
      }
    };

    fetchReports();
    fetchImages();
    fetchFirebaseConfig();
  }, [auth]);

  // Get the current environment
  const env = process.env.REACT_APP_ENV || 'dev';

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>Debug Page</Typography>
      <Typography variant="h6">Current Environment: {env}</Typography> {/* Display the current environment */}
      {user && (
        <Box sx={{ marginBottom: 2 }}>
          <Typography variant="h6">User Profile:</Typography>
          <Typography>Email: {user.email}</Typography>
          <Typography>UID: {user.uid}</Typography>
          <Typography>Display Name: {user.display_name || 'N/A'}</Typography>
        </Box>
      )}
      <Box sx={{ marginBottom: 2 }}>
        <Typography variant="h6">Reports:</Typography>
        {reports.length > 0 ? (
          reports.map(report => (
            <Box key={report.id} sx={{ marginBottom: 2 }}>
              <Typography>Report ID: {report.id}</Typography>
              <Typography>URL: {report.url}</Typography>
              <Typography>Created At: {formatDate(report.created_at)}</Typography>
              <Typography>Status: {report.status || 'Unknown'}</Typography>
              <Typography>Overall Score: {report.overall_score || 'N/A'}</Typography>
              <Typography>Page Count: {report.page_count || 'N/A'}</Typography>
              <Box sx={{ marginTop: 2 }}>
                <Typography variant="h6">Screenshots:</Typography>
                {report.screenshot_urls ? (
                  Object.entries(report.screenshot_urls).map(([url, description]) => (
                    <Box key={url} sx={{ marginBottom: 1 }}>
                      <Typography>{description}</Typography>
                      <img src={url} alt={description} style={{ maxWidth: '200px' }} />
                    </Box>
                  ))
                ) : (
                  <Typography>No screenshots found.</Typography>
                )}
              </Box>
            </Box>
          ))
        ) : (
          <Typography>No reports found.</Typography>
        )}
      </Box>
      <Box>
        <Typography variant="h6">Images:</Typography>
        {images.length > 0 ? (
          images.map(image => (
            <Box key={image.id} sx={{ marginBottom: 1 }}>
              <Typography>Image ID: {image.id}</Typography>
              <img src={image.url} alt={image.description} style={{ maxWidth: '200px' }} />
            </Box>
          ))
        ) : (
          <Typography>No images found.</Typography>
        )}
      </Box>
      <Button variant="contained" onClick={() => window.location.reload()} sx={{ marginTop: 2 }}>
        Refresh Data
      </Button>
      <Box sx={{ marginTop: 2 }}>
        <Typography variant="h6">Firebase Configuration:</Typography>
        <Typography>API Key: {firebaseConfig.apiKey || 'Not available'}</Typography>
        <Typography>Auth Domain: {firebaseConfig.authDomain || 'Not available'}</Typography>
        <Typography>Project ID: {firebaseConfig.projectId || 'Not available'}</Typography>
        <Typography>Storage Bucket: {firebaseConfig.storageBucket || 'Not available'}</Typography>
        <Typography>Messaging Sender ID: {firebaseConfig.messagingSenderId || 'Not available'}</Typography>
        <Typography>App ID: {firebaseConfig.appId || 'Not available'}</Typography>
        <Typography>Measurement ID: {firebaseConfig.measurementId || 'Not available'}</Typography>
      </Box>
    </Box>
  );
};

export default DebugPage;
