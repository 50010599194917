import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const formatDate = (dateString) => {
    const date = dayjs.utc(dateString).format('YYYY-MM-DD HH:mm:ss [UTC]');
    return date;
};

export const formatDateShort = (dateString) => {
  const date = dayjs.utc(dateString).format('YYYY-MM-DD [UTC]');
  return date;
};