
import FAQ from '../components/FAQ';
import Footer from '../components/Footer';
import { Divider } from '@mui/material';

export default function LandingPage() {
  return (
        <>
        <FAQ />
        <Divider />
        <Footer />
        </>
  );
}
