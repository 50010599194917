import React, { useEffect, useRef } from 'react';
import { Paper, Typography, Box, LinearProgress } from '@mui/material';

const TerminalProgress = ({ progress, events }) => {
  const terminalRef = useRef(null);

  useEffect(() => {
    if (terminalRef.current) {
      terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
    }
  }, [events]);

  return (
    <Paper
      elevation={3}
      sx={{
        width: '100%',
        height: 300,
        backgroundColor: '#1e1e1e',
        color: 'white',
        fontFamily: 'monospace',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          padding: '5px 10px',
          borderBottom: '1px solid #444',
          backgroundColor: '#333',
        }}
      >
        <Box
          sx={{
            width: 12,
            height: 12,
            borderRadius: '50%',
            backgroundColor: '#ff5f56',
            marginRight: 1,
          }}
        />
        <Box
          sx={{
            width: 12,
            height: 12,
            borderRadius: '50%',
            backgroundColor: '#ffbd2e',
            marginRight: 1,
          }}
        />
        <Box
          sx={{
            width: 12,
            height: 12,
            borderRadius: '50%',
            backgroundColor: '#27c93f',
          }}
        />
      </Box>
      <Box sx={{ padding: 2 }}>
        <LinearProgress variant="determinate" value={progress} sx={{ height: 10, borderRadius: 5 }} />
        <Typography variant="body2" sx={{ textAlign: 'center', marginTop: 1 }}>
          {`Progress: ${Math.round(progress)}%`}
        </Typography>
      </Box>
      <Box
        ref={terminalRef}
        sx={{
          height: 230,
          overflowY: 'auto',
          padding: 2,
          '&::-webkit-scrollbar': {
            width: '0.4em',
          },
          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            outline: '1px solid slategrey',
          },
        }}
      >
        {events.map((event, index) => (
          <Typography key={index} variant="body2" sx={{ marginBottom: 1 }}>
            {`> ${event.status}: ${event.url}`}
          </Typography>
        ))}
      </Box>
    </Paper>
  );
};

export default TerminalProgress;