import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

export const API_BASE_URL = process.env.REACT_APP_API_URL;
console.log(API_BASE_URL);

// Function to fetch Firebase config from the API
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db_name = `${process.env.REACT_APP_ENV}-db`;
console.log(db_name);
const db = getFirestore(app, { databaseId: db_name });
const storage = getStorage(app);
const googleProvider = new GoogleAuthProvider();

// Add this line after initializing the app
const analytics = getAnalytics(app);

// Export analytics
export { auth, db, storage, googleProvider, analytics };