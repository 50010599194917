import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Header from './components/Header';
import { AuthProvider } from './AuthContext';
import PrivateRoute from './PrivateRoute';

// Import components directly
import Review from './pages/Review';
import FAQ from './pages/FAQ';
import Highlights from './pages/Highlights';
import Features from './pages/Features';

import Profile from './pages/Profile';
import Dashboard from './pages/Dashboard';
import Pricing from './pages/Pricing';
import LandingPage from './pages/LandingPage';
import ForgotPassword from './pages/ForgotPassword';
import Login from './pages/Login';
import DebugPage from './pages/Debug';
import Report from './pages/Report';
import SubscriptionResult from './pages/SubscriptionResult';
import SubscriptionCancelled from './pages/SubscriptionCancelled';
import Contact from './pages/Contact';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import Press from './pages/Press';
import AboutUs from './pages/AboutUs';
import Demos from './components/Demos';
import SignUp from './pages/SignUp';
import { usePageTracking } from './components/usePageTracking';
import { analytics } from './config';
import { logEvent } from "firebase/analytics";


const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#58dbef',
    },
    secondary: {
      main: '#ffffff'
    },
    background: {
      default: '#1a1a1a',
      paper: '#2c2c2c',
    },
    success: {
      main: '#00E676', // A bright green
      light: '#69F0AE',
      dark: '#00C853',
      contrastText: '#000000',
    },
    info: {
      main: '#CDDC39', // A bright lime green (between success and warning)
      light: '#E6EE9C',
      dark: '#AFB42B',
      contrastText: '#000000',
    },
    warning: {
      main: '#FFEA00', // A bright yellow
      light: '#FFFF8D',
      dark: '#FFD600',
      contrastText: '#000000',
    },
    semiError: {
      main: '#FF9100', // A bright orange
      light: '#FFAB40',
      dark: '#FF6D00',
      contrastText: '#000000',
    },
    error: {
      main: '#FF1744', // A bright red
      light: '#FF5252',
      dark: '#D50000',
      contrastText: '#ffffff',
    },
  },
});

function AppContent() {
  const location = useLocation();
  const showHeader = (location.pathname !== '/dashboard' && location.pathname !== '/report');

  usePageTracking(); // Add this line

  return (
    <AuthProvider>
      {showHeader && <Header />}
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/login" element={<Login />} />
        <Route path="/profile" element={<PrivateRoute element={<Profile />} />} />
        <Route path="/report" element={<PrivateRoute element={<Report />} />} />
        <Route path="/dashboard" element={<PrivateRoute element={<Dashboard />} />} />
        <Route path="/debug" element={<PrivateRoute element={<DebugPage />} />} />
        <Route path="/highlights" element={<Highlights />} />
        <Route path="/features" element={<Features />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/subscription-cancelled" element={<SubscriptionCancelled />} />
        <Route path="/subscription-result" element={<SubscriptionResult />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/review" element={<Review />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/press" element={<Press />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/demos" element={<Demos />} />
      </Routes>
    </AuthProvider>
  );
}

function App() {
  useEffect(() => {
    logEvent(analytics, 'app_loaded');
  }, []);

  console.log("App.js App component rendered");
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Router>
          <AppContent />
        </Router>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;